import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import {
  AwardeePrivateData,
  GoldenBookAwardee,
  GoldenBookEntry,
  GoldenBookEntryInput,
} from '../types';
import { useEncryption } from './useEncryption';

interface GoldenBookEntryContextType {
  entry: GoldenBookEntry | undefined;
  awardeePrivateData: AwardeePrivateData | undefined;
  editEntry: (updatedEntry: GoldenBookEntryInput) => Promise<void>;
  editAwardee: (updatedAwardee: GoldenBookAwardee) => Promise<void>;
  editAwardeePrivateData: (
    updatedAwardeePrivateData: AwardeePrivateData
  ) => Promise<void>;
  refreshEntry: () => Promise<void>;
  refreshAwardeePrivateData: () => Promise<void>;
}

const GoldenBookEntryContext = createContext<
  GoldenBookEntryContextType | undefined
>(undefined);

interface Props {
  entryId: number;
  children: React.ReactNode;
}
export const GoldenBookEntryProvider: React.FC<Props> = ({
  children,
  entryId,
}) => {
  const value = useProvideEntry(entryId);
  return (
    <GoldenBookEntryContext.Provider value={value}>
      {children}
    </GoldenBookEntryContext.Provider>
  );
};

const useProvideEntry = (entryId: number): GoldenBookEntryContextType => {
  const { error } = useNotification();
  const { encrypt, decrypt, isLocked } = useEncryption();
  const [entry, setEntry] = useState<GoldenBookEntry>();
  const [awardeePrivateData, setAwardeePrivateData] =
    useState<AwardeePrivateData>();

  const fetchEntry = useCallback(async () => {
    const newEntry = await apiClient.getGoldenBookEntry(entryId);
    setEntry(newEntry);
  }, [entryId]);

  const fetchAwardeePrivateData = useCallback(async () => {
    if (entry && !isLocked) {
      const awardeeId = entry.awardee.id;
      const cipherData = await apiClient.getAwardeePrivateData(awardeeId);
      if (cipherData) {
        const privateData = await decrypt(cipherData);
        setAwardeePrivateData(privateData);
      } else {
        setAwardeePrivateData({ id: awardeeId });
      }
    }
  }, [decrypt, entry, isLocked]);

  const editEntry = useCallback(
    async (updatedEntry: GoldenBookEntryInput) => {
      await apiClient.putGoldenBookEntry(updatedEntry);
      await fetchEntry();
    },
    [fetchEntry]
  );

  const editAwardee = useCallback(
    async (updatedAwardee: GoldenBookAwardee) => {
      await apiClient.putAwardee(updatedAwardee);
      await fetchEntry();
    },
    [fetchEntry]
  );

  const editAwardeePrivateData = useCallback(
    async (updatedAwardeePrivateData: AwardeePrivateData) => {
      if (entry && !isLocked) {
        const awardeeId = entry.awardee.id;
        const cipherData = await encrypt(updatedAwardeePrivateData);
        await apiClient.putAwardeePrivateData(awardeeId, cipherData);
        await fetchAwardeePrivateData();
      }
    },
    [encrypt, entry, fetchAwardeePrivateData, isLocked]
  );

  useEffect(() => {
    fetchEntry().catch(error('Errore durante lo scaricamento dei dati'));
  }, [error, fetchEntry]);

  useEffect(() => {
    fetchAwardeePrivateData().catch(
      error('Errore durante lo scaricamento dei dati privati')
    );
  }, [error, fetchAwardeePrivateData]);

  return {
    entry,
    awardeePrivateData,
    editEntry,
    editAwardee,
    editAwardeePrivateData,
    refreshEntry: fetchEntry,
    refreshAwardeePrivateData: fetchAwardeePrivateData,
  };
};

export const useGoldenBookEntry = (): GoldenBookEntryContextType => {
  const context = useContext(GoldenBookEntryContext);

  if (context === undefined) {
    throw new Error(
      'useGoldenBookEntry must be used within a GoldenBookEntryProvider'
    );
  }

  return context;
};

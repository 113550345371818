import {
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';

import { GoldenBookEntry } from '../../../../lib/types';
import EditAwardDataDialogButton from '../dialogs/EditAwardDataDialogButton';
import AwardDisplay from './AwardDisplay';
import LabeledStringDisplay from './LabeledStringDisplay';
import PublishedText from './PublishedText';

interface Props {
  entry: GoldenBookEntry;
  isSecretary: boolean;
  isGrantAward: boolean;
}

const AwardDataDisplay: React.FC<Props> = ({
  entry,
  isSecretary,
  isGrantAward,
}) => (
  <Card variant="outlined">
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" mb={2}>
        Dati premio
      </Typography>
      <Stack mb={2} direction="column">
        <AwardDisplay
          awardId={entry.awardId}
          otherAward={entry.otherAward}
          year={entry.year}
        />
        <PublishedText isPublic={entry.published} />
      </Stack>
      <LabeledStringDisplay label="Vincita CHF" value={entry.amountWon} />
      <LabeledStringDisplay label="Regalo" value={entry.present} />
      {/* Show only for grants */}
      {isGrantAward && (
        <LabeledStringDisplay
          label="Anno di diploma Master"
          value={entry.yearOfMasterGraduation}
        />
      )}
      {/* Show only for NON grants */}
      {!isGrantAward && (
        <LabeledStringDisplay
          label="Titolo diploma o progetto"
          value={entry.projectOrDiplomaTitle}
        />
      )}
      <LabeledStringDisplay
        label="Luogo premiazione"
        value={entry.awardPlace}
      />
      <LabeledStringDisplay
        label="Luogo audizione"
        value={entry.auditionPlace}
      />
      <LabeledStringDisplay
        label="Miglior presentazione"
        value={entry.bestPresentationAward ? 'Sì' : 'No'}
      />
    </CardContent>
    {isSecretary && (
      <CardActions>
        <EditAwardDataDialogButton entry={entry} />
      </CardActions>
    )}
  </Card>
);

export default AwardDataDisplay;

interface Entity {
  id: number;
}

export interface Award extends Entity {
  name: string;
  projectDescription: string;
  withProjectCandidacies: boolean;
}

export enum AwardEditionVotingState {
  EDITION_OPEN = 'EDITION_OPEN',
  VOTING_STARTED = 'VOTING_STARTED',
  VOTING_ENDED = 'VOTING_ENDED',
}

export interface AwardEdition extends Entity {
  award: Award;
  editionYear: string;
  votingState: AwardEditionVotingState;
}

export interface Candidacy extends Entity {
  candidates: { firstName: string; lastName: string }[];
  projectTitle?: string;
  diplomaTitle?: string;
}

export interface CandidacyWithIndex extends Candidacy {
  index: number;
}

export interface CandidacyIndexInput {
  id: number;
  index: number;
}

export enum AwardEditionJudgeVotingState {
  VOTING_NOT_STARTED = 'VOTING_NOT_STARTED',
  VOTING_ONGOING = 'VOTING_ONGOING',
  VOTING_COMPLETED = 'VOTING_COMPLETED',
}

export interface Judge {
  judgeKeycloakId: string;
  judgeFirstName: string;
  judgeLastName: string;
  votingState: AwardEditionJudgeVotingState;
}

interface Criterion extends Entity {
  description: string;
}

export interface WeightedCriterion {
  criterion: Criterion;
  weight: number;
}

export type Grade = 6 | 7 | 8 | 9 | 10;

interface CriterionGrade {
  criterion: Criterion;
  grade: Grade;
}

export interface JudgeGrade {
  awardEditionId: number;
  candidacyId: number;
  judgeKeycloakId: string;
  grades: CriterionGrade[];
}

export interface JudgeGradesAndStatus {
  judgeGrades: JudgeGrade[];
  judgeVotingState: AwardEditionJudgeVotingState;
}

export interface CriterionGradeInput {
  criterionId: number;
  grade: Grade;
}
export interface JudgeGradeInput {
  candidacyId: number;
  grades: CriterionGradeInput[];
}

export interface RankingEntry {
  candidacy: Candidacy;
  grade: number; // not Grade because it's a sum of Grades
}
export interface Ranking {
  ranking: RankingEntry[];
  presentationRanking?: RankingEntry[];
}

export enum Role {
  judge = 'judge',
  secretary = 'secretary',
  boardmember = 'boardmember',
}

export interface User {
  keycloakId: string;
  firstName: string;
  lastName: string;
  roles: Role[];
}

export interface GoldenBookAwardee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GoldenBookEntry {
  id: number;
  awardee: GoldenBookAwardee;
  year: string;
  awardId?: number;
  otherAward?: string;
  amountWon?: number;
  present?: string;
  published: boolean;
  yearOfMasterGraduation?: string;
  projectOrDiplomaTitle?: string;
  awardPlace?: string;
  auditionPlace?: string;
  bestPresentationAward: boolean;
}

export type GoldenBookEntryInput = Omit<GoldenBookEntry, 'awardee'> & {
  awardeeId: string;
};

export interface AwardeePrivateData {
  id: number;
  email?: string;
  phone?: string;
  birthYear?: number;
  address?: string;
  city?: string;
  photo?: string;
}

import { useEffect, useState } from 'react';

import { Skeleton, Typography } from '@mui/material';

import apiClient from '../../../../lib/apiClient';
import useNotification from '../../../../lib/hooks/useNotification';
import { Award } from '../../../../lib/types';
import { convertGrantNameToSingular } from '../../../../lib/utils/string';

interface Props {
  awardId?: number;
  otherAward?: string;
  year: string;
}

const AwardDisplay: React.FC<Props> = ({ awardId, otherAward, year }) => {
  const { error } = useNotification();
  const [awardsById, setAwardsById] = useState<Record<number, Award>>({});

  useEffect(() => {
    apiClient
      .getPublicAwards()
      .then((awards) =>
        Object.fromEntries(awards.map((award) => [award.id, award]))
      )
      .then(setAwardsById)
      .catch(error('Errore durante lo scaricamento dei premi.'));
  }, [error]);

  if (otherAward) {
    return (
      <>
        {otherAward} • {year}
      </>
    );
  } else if (!awardId) {
    return <>NESSUN PREMIO • {year}</>;
  } else {
    return (
      <Typography
        variant="h6"
        sx={{ fontWeight: 'initial' }}
        color="text.secondary"
        gutterBottom
      >
        {Object.keys(awardsById).length === 0 && awardId ? (
          <Skeleton />
        ) : (
          <>
            {convertGrantNameToSingular(awardsById[awardId].name)} • {year}
          </>
        )}{' '}
      </Typography>
    );
  }
};

export default AwardDisplay;

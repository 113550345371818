import { Button } from '@mui/material';

import { useAwardEditions } from '../../lib/context/useAwardEditions';
import {
  AwardEdition,
  AwardEditionVotingState as State,
} from '../../lib/types';
import CloseVoting from './controls/CloseVoting';
import OpenVoting from './controls/OpenVoting';

interface Props {
  awardEdition: AwardEdition;
  refresh: () => Promise<void>;
}
const Control = ({ awardEdition, refresh }: Props): JSX.Element => {
  const controls: Record<State, JSX.Element> = {
    [State.EDITION_OPEN]: (
      <OpenVoting awardEdition={awardEdition} refresh={refresh} />
    ),
    [State.VOTING_STARTED]: (
      <CloseVoting awardEdition={awardEdition} refresh={refresh} />
    ),
    [State.VOTING_ENDED]: (
      <Button variant="outlined" color="info" disabled>
        Classifica disponibile
      </Button>
    ),
  };
  return controls[awardEdition.votingState];
};

const VotingControls = (): JSX.Element => {
  const { selectedAwardEdition, refreshAwardEditions } = useAwardEditions();

  if (!selectedAwardEdition) {
    return <div></div>;
  }

  return (
    <Control
      awardEdition={selectedAwardEdition}
      refresh={refreshAwardEditions}
    />
  );
};

export default VotingControls;

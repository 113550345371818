import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { AwardEdition } from '../../../lib/types';
import GenericCard, { GenericCardInteractionProps } from './GenericCard';

interface AwardEditionCardProps {
  awardEdition: AwardEdition;
}

type Props = GenericCardInteractionProps & AwardEditionCardProps;

const AwardEditionCard = ({ awardEdition, ...props }: Props): JSX.Element => (
  <GenericCard
    title={awardEdition.award.name}
    subtitle={awardEdition.award.projectDescription}
    icon={<EmojiEventsIcon />}
    {...props}
  />
);

export default AwardEditionCard;

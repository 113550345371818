import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import AddCandidacyForm from '../forms/AddCandidacyForm';

interface Props {
  withProjectTitle: boolean;
}

const AddCandidacyDialogButton: React.FC<Props> = ({
  withProjectTitle,
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        aria-label="add"
        variant="text"
        color="info"
        onClick={handleOpen}
        endIcon={<AddIcon />}
      >
        <Typography variant="body2">Aggiungi</Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Aggiungi candidatura</DialogTitle>
        <DialogContent>
          <AddCandidacyForm
            withProjectTitle={withProjectTitle}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCandidacyDialogButton;

import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from '../../../lib/context/useUser';
import LoadingPage from '../../../pages/LoadingPage';

const PrivateRoutes = () => {
  const { isLoading, user } = useUser();

  if (isLoading) {
    return <LoadingPage />;
  } else if (!user) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoutes;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import { AwardEdition } from '../types';

interface AwardEditionsContextType {
  awardEditions: AwardEdition[];
  selectedAwardEdition?: AwardEdition;
  selectAwardEditionById: (id: number) => void;
  refreshAwardEditions: () => Promise<void>;
}

const AwardEditionsContext = createContext<
  AwardEditionsContextType | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
}
export const AwardEditionsProvider = ({ children }: Props): JSX.Element => {
  const value = useProvideAwardEditions();
  return (
    <AwardEditionsContext.Provider value={value}>
      {children}
    </AwardEditionsContext.Provider>
  );
};

const useProvideAwardEditions = (): AwardEditionsContextType => {
  const { error } = useNotification();
  const [awardEditions, setAwardEditions] = useState<AwardEdition[]>([]);
  const [selectedAwardEdition, setSelectedAwardEdition] = useState<
    AwardEdition | undefined
  >();

  const selectAwardEditionById = useCallback(
    (id: number) =>
      setSelectedAwardEdition(
        awardEditions.find((awardEdition) => awardEdition.id === id)
      ),
    [awardEditions]
  );

  const fetchAwardEditions = useCallback(async () => {
    const newAwardEditions = await apiClient.getAwardEditions();
    newAwardEditions.sort((a, b) => a.id - b.id);
    setAwardEditions(newAwardEditions);
  }, []);

  useEffect(() => {
    fetchAwardEditions().catch(
      error('Errore durante lo scaricamento dei premi.')
    );
  }, [error, fetchAwardEditions]);

  // Re-select the same award edition after an update
  useEffect(() => {
    if (selectedAwardEdition) {
      selectAwardEditionById(selectedAwardEdition.id);
    }
  }, [awardEditions, selectAwardEditionById, selectedAwardEdition]);

  return {
    awardEditions,
    selectedAwardEdition,
    selectAwardEditionById,
    refreshAwardEditions: fetchAwardEditions,
  };
};

export const useAwardEditions = (): AwardEditionsContextType => {
  const context = useContext(AwardEditionsContext);

  if (context === undefined) {
    throw new Error(
      'useAwardEditions must be used within an AwardEditionsProvider'
    );
  }

  return context;
};

import GavelIcon from '@mui/icons-material/Gavel';

import {
  Judge,
  AwardEditionJudgeVotingState as VotingState,
} from '../../../lib/types';
import GenericCard, {
  BadgeSpec,
  GenericCardInteractionProps,
} from './GenericCard';

interface JudgeCardProps {
  judge: Judge;
}

type Props = GenericCardInteractionProps & JudgeCardProps;

const JudgeCard = ({ judge, ...props }: Props): JSX.Element => (
  <GenericCard
    title={`${judge.judgeLastName} ${judge.judgeFirstName}`}
    icon={<GavelIcon />}
    badge={getBadge(judge)}
    {...props}
  />
);

const getBadge = (judge: Judge): BadgeSpec | undefined => {
  if (judge.votingState === VotingState.VOTING_ONGOING) {
    return { color: 'warning', content: '!' };
  } else if (judge.votingState === VotingState.VOTING_COMPLETED) {
    return { color: 'success', content: '✓' };
  }
};

export default JudgeCard;

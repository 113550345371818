import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { RankingEntry } from '../../../lib/types';
import PrizeIcon from './PrizeIcon';

interface RankingEntryWithRank {
  rank: number;
  entry: RankingEntry;
}

interface Props {
  entries: RankingEntry[];
}
const RankingList = ({ entries }: Props): JSX.Element => {
  const sortedEntries = [...entries].sort((a, b) => b.grade - a.grade); // inverse order
  const entriesWithRankingIndex = sortedEntries.reduce(
    (previousEntries, currentEntry, i) => {
      const previousEntry = [...previousEntries].pop();
      if (!previousEntry || previousEntry.entry.grade > currentEntry.grade) {
        // First entry, or new entry with lower grade,
        // gets next available rank
        return [...previousEntries, { entry: currentEntry, rank: i + 1 }];
      }
      // New entry with same grade as previous entry,
      // gets same rank as previous
      return [
        ...previousEntries,
        { entry: currentEntry, rank: previousEntry.rank },
      ];
    },
    [] as RankingEntryWithRank[]
  );

  return (
    <List dense>
      {entriesWithRankingIndex.map(({ entry, rank: index }) => {
        const subtitle = (
          <>
            {entry.candidacy.diplomaTitle?.trim() && (
              <>
                {entry.candidacy.diplomaTitle?.trim()} <br />
              </>
            )}
            {entry.candidacy.projectTitle || ''}
          </>
        );

        return (
          <ListItem
            className="ranking-list-item"
            key={`${entry.candidacy.id}-${Date.now()}`}
            secondaryAction={entry.grade}
          >
            <ListItemIcon>
              <PrizeIcon fontSize="large" rank={index} />
            </ListItemIcon>
            <ListItemText
              primary={entry.candidacy.candidates
                .map((c) => `${c.lastName} ${c.firstName}`)
                .join(', ')}
              secondary={subtitle}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default RankingList;

import { ReactNode } from 'react';

import Box from '@mui/system/Box';

import Footer from './Footer';
import TopBar from './TopBar';

interface Props {
  children: ReactNode;
}

const Wrapper = ({ children }: Props): JSX.Element => (
  <Box display="flex" flexDirection="column" sx={{ height: '100vh' }}>
    <TopBar />
    <Box sx={{ flexGrow: 1, minHeight: 0 }}>{children}</Box>
    <Footer />
  </Box>
);

export default Wrapper;

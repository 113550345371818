import { useEffect, useState } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Skeleton,
} from '@mui/material';

import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import apiClient from '../../../../lib/apiClient';
import useNotification from '../../../../lib/hooks/useNotification';
import { Award } from '../../../../lib/types';
import { convertGrantNameToSingular } from '../../../../lib/utils/string';

interface Props<TFieldValues> extends Omit<SelectProps, 'name' | 'label'> {
  name: Path<TFieldValues>;
  label: string;
}

const AwardSelect: React.FC<Props<FieldValues>> = ({
  name,
  label,
  ...selectProps
}) => {
  const { error } = useNotification();
  const { control } = useFormContext<FieldValues>();
  const [awards, setAwards] = useState<Award[]>([]);

  useEffect(() => {
    apiClient
      .getPublicAwards()
      .then(setAwards)
      .catch(error('Errore durante lo scaricamento dei premi.'));
  }, [error]);

  return (
    <FormControl fullWidth>
      <InputLabel id="select-helper-label">{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, ...restFieldProps },
          fieldState: { error },
        }) =>
          awards.length > 0 ? (
            <Select
              variant="standard"
              labelId="demo-simple-select-helper-label"
              fullWidth
              label={label}
              error={!!error}
              inputRef={ref}
              {...restFieldProps}
              {...selectProps}
            >
              <MenuItem value="">
                <em>Nessuno</em>
              </MenuItem>
              {awards.map((award) => (
                <MenuItem key={award.id} value={award.id}>
                  {convertGrantNameToSingular(award.name)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Skeleton />
          )
        }
      />
    </FormControl>
  );
};

export default AwardSelect;

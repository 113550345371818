import { Grid, Skeleton } from '@mui/material';

import { useEncryption } from '../../../../lib/context/useEncryption';
import { useGoldenBookEntry } from '../../../../lib/context/useGoldenBookEntry';
import { useUser } from '../../../../lib/context/useUser';
import StyledPaperCard from '../../../common/cards/StyledPaperCard';
import EncryptionLock from '../../lock/EncryptionLock';
import AwardeePrivateDataDisplay from '../display/AwardeePrivateDataDisplay';

const AwardeePrivateDataWrapper: React.FC = () => {
  const { isSecretary } = useUser();
  const { isLocked } = useEncryption();
  const { awardeePrivateData } = useGoldenBookEntry();

  if (isLocked) {
    return (
      <StyledPaperCard variant="outlined">
        <Grid
          container
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <EncryptionLock />
          </Grid>
        </Grid>
      </StyledPaperCard>
    );
  } else if (awardeePrivateData) {
    return (
      <AwardeePrivateDataDisplay
        awardeePrivateData={awardeePrivateData}
        isSecretary={isSecretary}
      />
    );
  } else {
    return <Skeleton variant="rounded" width="100%" height="100%" />;
  }
};

export default AwardeePrivateDataWrapper;

import { Button, Container } from '@mui/material';

import apiClient from '../../lib/apiClient';
import { useAwardEditions } from '../../lib/context/useAwardEditions';
import { useJudgeGrades } from '../../lib/context/useJudgeGrades';
import useNotification from '../../lib/hooks/useNotification';
import {
  AwardEdition,
  AwardEditionJudgeVotingState as VotingState,
} from '../../lib/types';
import ConfirmationDialogButton from '../common/dialogs/ConfirmationDialogButton';

interface Props {
  awardEdition: AwardEdition;
}
const LockButton = ({ awardEdition }: Props): JSX.Element => {
  const { error, info } = useNotification();
  const { refreshJudgeGrades, votingState } = useJudgeGrades();

  const postLockVotes = () => {
    apiClient
      .postCompleteJudgeGrade(awardEdition.id)
      .then(refreshJudgeGrades)
      .then(info('Voti salvati.'))
      .catch(error('Una o più candidature hanno voti mancanti.'));
  };

  const lockVotesDialog = {
    title: 'Completare votazione?',
    description:
      'Una volta completata la votazione non è più possibile cambiare i voti.',
    okText: 'Conferma',
  };

  if (votingState === VotingState.VOTING_ONGOING) {
    return (
      <ConfirmationDialogButton
        fullWidth
        action={postLockVotes}
        dialog={lockVotesDialog}
        tooltip="Completa votazione"
        variant="contained"
        color="success"
        size="large"
      >
        Completa votazione
      </ConfirmationDialogButton>
    );
  } else if (votingState === VotingState.VOTING_NOT_STARTED) {
    return (
      <Button disabled fullWidth size="large">
        Voto non ancora iniziato
      </Button>
    );
  } else {
    return (
      <Button disabled fullWidth size="large">
        Voto completato
      </Button>
    );
  }
};

const LockVotes = (): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();

  if (!selectedAwardEdition) {
    return <div></div>;
  }

  return (
    <Container sx={{ pb: 2 }}>
      <LockButton awardEdition={selectedAwardEdition} />
    </Container>
  );
};

export default LockVotes;

import { Grid, Typography } from '@mui/material';

interface Props {
  label: string;
  value?: string | number;
  multiline?: boolean;
}

const LabeledStringDisplay: React.FC<Props> = ({
  label,
  value,
  multiline = false,
}) => {
  if (multiline) {
    return <>aaaa</>;
  } else {
    return (
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {label}
          </Typography>
        </Grid>
        <Grid xs={7}>
          <Typography variant="body1" gutterBottom>
            {value || '-'}
          </Typography>
        </Grid>
      </Grid>
    );
  }
};

export default LabeledStringDisplay;

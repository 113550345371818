import { GoldenBookEntry } from '../../../../lib/types';
import GenericFormDialogButton from '../../../common/dialogs/GenericFormDialogButton';
import EditAwardDataForm from '../forms/EditAwardDataForm';

interface Props {
  entry: GoldenBookEntry;
}

const EditAwardDataDialogButton: React.FC<Props> = ({ entry }) => (
  <GenericFormDialogButton
    color="info"
    variant="text"
    buttonLabel="Modifica"
    dialogTitle="Modifica dati premio"
    renderForm={(handleClose) => (
      <EditAwardDataForm entry={entry} handleClose={handleClose} />
    )}
  />
);

export default EditAwardDataDialogButton;

import { Navigate, useParams } from 'react-router-dom';

import EntryContainer from '../components/goldenbook/entry/EntryContainer';
import Wrapper from '../components/layout/Wrapper';
import { GoldenBookProvider } from '../lib/context/useGoldenBook';
import { GoldenBookEntryProvider } from '../lib/context/useGoldenBookEntry';

const GoldenBookEntryPage: React.FC = () => {
  const { entryId } = useParams();

  if (!entryId) {
    return <Navigate to="/albodoro" />;
  }

  return (
    <GoldenBookProvider>
      <GoldenBookEntryProvider entryId={Number.parseInt(entryId)}>
        <Wrapper>
          <EntryContainer />
        </Wrapper>
      </GoldenBookEntryProvider>
    </GoldenBookProvider>
  );
};

export default GoldenBookEntryPage;

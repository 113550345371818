import { useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';

import { CandidacyWithIndex } from '../../../lib/types';
import EditCandidacyForm from '../forms/EditCandidacyForm';
import GenericCard, { GenericCardInteractionProps } from './GenericCard';

interface Props extends GenericCardInteractionProps {
  candidacy: CandidacyWithIndex;
  editIndex: (index: number) => Promise<void>;
  possibleIndices: number[];
}

const EditableCandidacyCard = ({
  candidacy,
  editIndex,
  possibleIndices,
  ...props
}: Props): JSX.Element => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsEditDialogOpen(true);
  };

  const handleClose = () => {
    setIsEditDialogOpen(false);
  };

  const indexInput = (
    <Select
      variant="standard"
      sx={{ width: 'auto', textAlign: 'right' }}
      value={candidacy.index}
      onChange={(e) => editIndex(e.target.value as number)}
    >
      {possibleIndices.map((i) => (
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      ))}
    </Select>
  );

  const names = candidacy.candidates
    .map((c) => `${c.lastName} ${c.firstName}`)
    .join(', ');
  const subtitle = (
    <>
      {candidacy.diplomaTitle?.trim() && (
        <>
          {candidacy.diplomaTitle?.trim()} <br />
        </>
      )}
      {candidacy.projectTitle || ''}
    </>
  );

  return (
    <>
      <GenericCard
        title={names}
        subtitle={subtitle}
        icon={<PersonIcon />}
        secondaryAction={indexInput}
        onClick={handleOpen}
        {...props}
      />
      <Dialog
        open={isEditDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Modifica candidatura</DialogTitle>
        <DialogContent>
          <EditCandidacyForm candidacy={candidacy} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditableCandidacyCard;

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useAwardEditions } from '../../lib/context/useAwardEditions';
import { useCandidacies } from '../../lib/context/useCandidacies';
import { useJudgeGrades } from '../../lib/context/useJudgeGrades';
import {
  AwardEdition,
  AwardEditionJudgeVotingState,
  AwardEditionVotingState,
  Candidacy,
} from '../../lib/types';
import CandidacyGrades from './CandidacyGrades';
import CandidacyNotes from './CandidacyNotes';

const GradesWrapper = (): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();
  const { selectedCandidacy } = useCandidacies();
  const { votingState } = useJudgeGrades();

  if (
    !selectedAwardEdition ||
    !hasVotingStartedOrEnded(selectedAwardEdition) ||
    !selectedCandidacy
  ) {
    const message = getMessage(selectedAwardEdition, selectedCandidacy);
    return (
      <Grid container height="100%" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography>{message}</Typography>
        </Grid>
      </Grid>
    );
  }

  const disabled =
    votingState !== AwardEditionJudgeVotingState.VOTING_ONGOING ||
    selectedAwardEdition.votingState !== AwardEditionVotingState.VOTING_STARTED;

  return (
    <Box height="100%" overflow="auto">
      <CandidacyGrades
        awardEdition={selectedAwardEdition}
        candidacy={selectedCandidacy}
        disabled={disabled}
      />
      <CandidacyNotes
        awardEdition={selectedAwardEdition}
        candidacy={selectedCandidacy}
        disabled={disabled}
      />
    </Box>
  );
};

const hasVotingStartedOrEnded = (awardEdition: AwardEdition): boolean =>
  awardEdition.votingState === AwardEditionVotingState.VOTING_STARTED ||
  awardEdition.votingState === AwardEditionVotingState.VOTING_ENDED;
const hasVotingStarted = (awardEdition: AwardEdition): boolean =>
  awardEdition.votingState === AwardEditionVotingState.VOTING_STARTED;

const getMessage = (
  awardEdition: AwardEdition | undefined,
  candidacy: Candidacy | undefined
): string => {
  if (!awardEdition) {
    return 'Selezionare un premio';
  } else if (!hasVotingStarted(awardEdition)) {
    const status =
      awardEdition.votingState === AwardEditionVotingState.VOTING_ENDED
        ? 'è concluso'
        : 'non è ancora iniziato';
    return `Il voto per questo premio ${status}`;
  } else if (!candidacy) {
    return 'Selezionare una candidatura';
  }
  return '';
};

export default GradesWrapper;

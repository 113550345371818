import { createContext, useCallback, useContext, useState } from 'react';

import { CipherData } from 'easy-web-crypto';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import { AwardeePrivateData } from '../types';
import crypto from '../utils/crypto';

interface EncryptionContextType {
  encrypt: (plainData: AwardeePrivateData) => Promise<CipherData>;
  decrypt: (cipherData: CipherData) => Promise<AwardeePrivateData>;
  unlock: (userKey: string) => Promise<void>;
  lock: () => void;
  isLocked: boolean;
}

const EncryptionContext = createContext<EncryptionContextType | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}
export const EncryptionProvider = ({ children }: Props): JSX.Element => {
  const value = useProvideEncryption();
  return (
    <EncryptionContext.Provider value={value}>
      {children}
    </EncryptionContext.Provider>
  );
};

const useProvideEncryption = (): EncryptionContextType => {
  const { error } = useNotification();
  const [isLocked, setIsLocked] = useState(true);
  const [mainKey, setMainKey] = useState<CryptoKey | undefined>();

  const isLockedError = () => new Error('Vault is locked');

  const getAndDecryptMainKey = async (
    b64StrUserKey: string
  ): Promise<CryptoKey> => {
    // Request encrypted mainKey from backend
    const encryptedMainKey = await apiClient.getEncryptedMainKey();
    // Import user key
    const userKey = await crypto.importKey(b64StrUserKey);
    // Decrypt and import mainKey using userKey
    const b64strDecryptedMainKey = await crypto.decrypt<string>(
      userKey,
      encryptedMainKey
    );
    return crypto.importKey(b64strDecryptedMainKey);
  };

  const lock = useCallback(() => {
    setMainKey(undefined);
    setIsLocked(true);
  }, []);

  const unlock = useCallback(
    async (b64StrUserKey: string) => {
      return getAndDecryptMainKey(b64StrUserKey)
        .then((decryptedMainKey) => {
          // Store mainKey locally and unlock
          setMainKey(decryptedMainKey);
          setIsLocked(false);
        })
        .catch(error("Errore durante l'apertura dei dati privati."));
    },
    [error]
  );

  const encrypt = useCallback(
    async (plainData: AwardeePrivateData): Promise<CipherData> => {
      if (mainKey) {
        return crypto.encrypt(mainKey, plainData);
      } else {
        throw isLockedError();
      }
    },
    [mainKey]
  );

  const decrypt = useCallback(
    async (cipherData: CipherData): Promise<AwardeePrivateData> => {
      if (mainKey) {
        return crypto.decrypt(mainKey, cipherData);
      } else {
        throw isLockedError();
      }
    },
    [mainKey]
  );

  return {
    encrypt,
    decrypt,
    lock,
    unlock,
    isLocked,
  };
};

export const useEncryption = (): EncryptionContextType => {
  const context = useContext(EncryptionContext);

  if (context === undefined) {
    throw new Error('useEncryption must be used within an EncryptionContext');
  }

  return context;
};

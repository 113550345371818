import { ChangeEvent, useCallback, useState } from 'react';

import KeyIcon from '@mui/icons-material/Key';
import {
  Button,
  DialogActions,
  DialogContentText,
  Stack,
  TextField,
} from '@mui/material';

import { useEncryption } from '../../../lib/context/useEncryption';
import useNotification from '../../../lib/hooks/useNotification';
import { readFileAsText } from '../../../lib/utils/files';
import VisuallyHiddenInput from '../../common/inputs/VisuallyHiddenInput';

interface Props {
  handleClose: () => void;
}

const EncryptionKeyInputForm: React.FC<Props> = ({ handleClose }) => {
  const [b64strKey, setB64strKey] = useState('');
  const { unlock } = useEncryption();
  const { error } = useNotification();

  const unlockEncryption = useCallback(async () => {
    handleClose();
    return unlock(b64strKey);
  }, [b64strKey, handleClose, unlock]);

  const loadKey = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (FileReader && event.target.files?.length) {
        const file = event.target.files[0];
        readFileAsText(file)
          .then(setB64strKey)
          .catch(error('Errore durante il caricamento della chiave segreta'));
      }
    },
    [error]
  );

  return (
    <>
      <DialogContentText mb={2}>
        Carica la tua chiave segreta per accedere ai dati privati dei premiati.
      </DialogContentText>
      <Stack direction="row" spacing={2}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          endIcon={<KeyIcon />}
        >
          Scegli file
          <VisuallyHiddenInput
            onChange={loadKey}
            type="file"
            accept="text/plain"
          />
        </Button>
        <TextField
          disabled
          variant="outlined"
          size="small"
          type="password"
          sx={{ flex: 1 }}
          value={
            b64strKey ? 'Una password super segreta che nessuno vedrà mai' : ''
          }
        />
      </Stack>
      <DialogActions>
        <Button onClick={handleClose}>Annulla</Button>
        <Button type="submit" onClick={unlockEncryption}>
          Conferma
        </Button>
      </DialogActions>
    </>
  );
};

export default EncryptionKeyInputForm;

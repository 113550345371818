import { Button, Typography } from '@mui/material';

import SplashWrapper from '../components/layout/SplashWrapper';
import { LOGO_SIZE } from '../config';
import apiClient from '../lib/apiClient';

const NoRolePage = () => {
  return (
    <SplashWrapper>
      <Typography
        variant="h5"
        color="error"
        sx={{ width: LOGO_SIZE, pb: 2, textAlign: 'center' }}
      >
        User has no known role
      </Typography>
      <Button
        size="large"
        color="error"
        variant="outlined"
        sx={{ width: LOGO_SIZE }}
        onClick={() => apiClient.logout()}
      >
        Logout
      </Button>
    </SplashWrapper>
  );
};
export default NoRolePage;

import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import { IS_DEVELOPMENT } from '../../config';

type NotificationHandlerBuilder = (message: string) => () => void;
interface ReturnType {
  info: NotificationHandlerBuilder;
  warning: NotificationHandlerBuilder;
  error: NotificationHandlerBuilder;
}
const useNotification = (): ReturnType => {
  const { enqueueSnackbar } = useSnackbar();

  const info = useCallback(
    (message: string) => () => enqueueSnackbar(message, { variant: 'info' }),
    [enqueueSnackbar]
  );
  const warning = useCallback(
    (message: string) => () => enqueueSnackbar(message, { variant: 'warning' }),
    [enqueueSnackbar]
  );
  const error = useCallback(
    (message: string) => (error?: Error) => {
      if (error && IS_DEVELOPMENT) {
        console.error(error);
      }
      return enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  return {
    info,
    error,
    warning,
  };
};

export default useNotification;

import apiClient from '../../../lib/apiClient';
import { useJudges } from '../../../lib/context/useJudges';
import useNotification from '../../../lib/hooks/useNotification';
import {
  AwardEdition,
  AwardEditionJudgeVotingState as VotingState,
} from '../../../lib/types';
import ConfirmationDialogButton from '../../common/dialogs/ConfirmationDialogButton';

interface Props {
  awardEdition: AwardEdition;
  refresh: () => Promise<void>;
}
const CloseVoting = ({ awardEdition: { id }, refresh }: Props): JSX.Element => {
  const { error, info } = useNotification();

  const { judges } = useJudges();
  const isSomeJudgeStillVoting = judges.some(
    (judge) => judge.votingState === VotingState.VOTING_ONGOING
  );
  const color = isSomeJudgeStillVoting ? 'warning' : 'success';

  return (
    <ConfirmationDialogButton
      variant="contained"
      color={color}
      action={() =>
        apiClient
          .postCompleteVoting(id)
          .then(refresh)
          .then(info('Voto chiuso.'))
          .catch(error('Alcuni giudici non hanno finito di votare.'))
      }
      dialog={{
        title: 'Chiudere voto?',
        description:
          'Dopo aver chiuso il voto i giudici non potranno più cambiare il voto. Per poter chiudere il voto, tutti i giudici devono aver confermato i loro voti.',
        okText: 'Chiudi voto',
      }}
      tooltip="Chiudi voto"
    >
      Chiudi voto
    </ConfirmationDialogButton>
  );
};

export default CloseVoting;

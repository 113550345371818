import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import apiClient from '../apiClient';
import { Role, User } from '../types';

interface UserContextType {
  isLoading: boolean;
  user: User | undefined;
  isSecretary: boolean;
  isSecretaryOrBoardMember: boolean;
  login: () => void;
  logout: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}
export const UserProvider = ({ children }: Props): JSX.Element => {
  const value = useProvideUser();
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useProvideUser = (): UserContextType => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  const isSecretary = useMemo(
    () => !!user && user.roles.includes(Role.secretary),
    [user]
  );
  const isSecretaryOrBoardMember = useMemo(
    () => isSecretary || (!!user && user.roles.includes(Role.boardmember)),
    [isSecretary, user]
  );

  const fetchUser = useCallback(async () => {
    const _user = await apiClient.getWhoami();
    setUser(_user);
  }, []);

  const login = useCallback(() => {
    apiClient.login();
  }, []);

  const logout = useCallback(async () => {
    await apiClient.logout();
    setUser(undefined);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchUser()
      .catch(() => setUser(undefined))
      .finally(() => setIsLoading(false));
  }, [fetchUser]);

  return {
    isLoading,
    isSecretary,
    isSecretaryOrBoardMember,
    user,
    login,
    logout,
  };
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
};

import { Grid } from '@mui/material';

import AwardEditionsList from '../components/common/lists/AwardEditionsList';
import CandidaciesList from '../components/common/lists/CandidaciesList';
import GradesWrapper from '../components/grades/GradesWrapper';
import LockVotes from '../components/grades/LockVotes';
import Wrapper from '../components/layout/Wrapper';
import { JudgeGradesProvider } from '../lib/context/useJudgeGrades';

const JudgePage: React.FC = () => (
  <JudgeGradesProvider>
    <Wrapper>
      <Grid container height="100%">
        <Grid
          item
          height="100%"
          overflow="auto"
          xs={3}
          sx={{ borderRight: '0.5px solid lightgrey' }}
        >
          <AwardEditionsList />
        </Grid>

        <Grid
          item
          container
          height="100%"
          direction="column"
          justifyContent="space-between"
          xs={3}
          sx={{ borderRight: '0.5px solid lightgrey' }}
        >
          <Grid item xs={11} overflow="auto">
            <CandidaciesList />
          </Grid>
          <Grid item>
            <LockVotes />
          </Grid>
        </Grid>

        <Grid item height="100%" overflow="auto" xs={6}>
          <GradesWrapper />
        </Grid>
      </Grid>
    </Wrapper>
  </JudgeGradesProvider>
);

export default JudgePage;

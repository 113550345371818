import apiClient from '../../../lib/apiClient';
import useNotification from '../../../lib/hooks/useNotification';
import { AwardEdition } from '../../../lib/types';
import ConfirmationDialogButton from '../../common/dialogs/ConfirmationDialogButton';

interface Props {
  awardEdition: AwardEdition;
  refresh: () => Promise<void>;
}
const OpenVoting = ({ awardEdition: { id }, refresh }: Props): JSX.Element => {
  const { error, info } = useNotification();

  return (
    <ConfirmationDialogButton
      variant="contained"
      color="success"
      action={() =>
        apiClient
          .postStartVoting(id)
          .then(refresh)
          .then(info('Voto aperto.'))
          .catch(error("Errore durante l'apertura del voto."))
      }
      dialog={{
        title: 'Aprire voto?',
        description:
          'Dopo aver aperto il voto i giudici possono iniziare a votare.',
        okText: 'Apri voto',
      }}
      tooltip="Apri voto"
    >
      Apri voto
    </ConfirmationDialogButton>
  );
};

export default OpenVoting;

import { TextField, TextFieldProps } from '@mui/material';

import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

interface Props<TFieldValues> extends Omit<TextFieldProps, 'name' | 'label'> {
  name: Path<TFieldValues>;
  label: string;
}

const TextInput: React.FC<Props<FieldValues>> = ({
  name,
  label,
  ...textFieldProps
}) => {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, ...restFieldProps },
        fieldState: { error },
      }) => (
        <TextField
          variant="standard"
          fullWidth
          label={label}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...restFieldProps}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default TextInput;

import { List, ListItem, ListSubheader, Typography } from '@mui/material';

import { useJudgeGrades } from '../../lib/context/useJudgeGrades';
import { AwardEdition, Candidacy } from '../../lib/types';
import GradeInput from '../common/inputs/GradeInput';

interface Props {
  awardEdition: AwardEdition;
  candidacy: Candidacy;
  disabled?: boolean;
}

const CandidacyGrades = ({
  awardEdition,
  candidacy,
  disabled = false,
}: Props): JSX.Element => {
  const {
    weightedCriteria,
    judgeGradesByCandidacyId,
    saveGradeOfCandidacyById,
  } = useJudgeGrades();

  const candidacyId = candidacy.id;
  const gradesByCriterionId = judgeGradesByCandidacyId[candidacyId];

  const gradeInputs = Object.values(weightedCriteria).map(
    (weightedCriterion) => {
      const criterionId = weightedCriterion.criterion.id;
      const gradeOrUndefined =
        gradesByCriterionId && gradesByCriterionId[criterionId];

      return (
        <ListItem key={criterionId}>
          <GradeInput
            weightedCriterion={weightedCriterion}
            disabled={disabled}
            grade={gradeOrUndefined}
            setGrade={(grade) =>
              saveGradeOfCandidacyById(candidacyId, {
                criterionId,
                grade,
              })
            }
          />
        </ListItem>
      );
    }
  );

  const candidateNames = candidacy.candidates
    .map((c) => `${c.lastName} ${c.firstName}`)
    .join(', ');

  return (
    <List
      sx={{ width: '100%' }}
      aria-labelledby="candidacy-grades-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="candidacy-grades-subheader"
          sx={{ pt: 3 }}
        >
          <Typography variant="h5" sx={{ color: 'initial' }}>
            {awardEdition.award.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: 'inherit',
              fontWeight: 'regular',
            }}
          >
            {candidacy.diplomaTitle && (
              <>
                {candidacy.diplomaTitle} <br />
              </>
            )}
            {awardEdition.award.projectDescription &&
              `${awardEdition.award.projectDescription}:`}
            {candidacy.projectTitle && ` ${candidacy.projectTitle}`}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: 'inherit', fontWeight: 'regular' }}
          >
            {candidateNames}
          </Typography>
        </ListSubheader>
      }
    >
      {gradeInputs}
    </List>
  );
};

export default CandidacyGrades;

import { ChangeEvent, useCallback, useState } from 'react';

import PhotoIcon from '@mui/icons-material/Photo';
import { Avatar, Button, Stack } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';

import useNotification from '../../../../lib/hooks/useNotification';
import { readFileAsDataUrl } from '../../../../lib/utils/files';
import { resizeImage } from '../../../../lib/utils/image';
import VisuallyHiddenInput from '../../../common/inputs/VisuallyHiddenInput';

interface CustomPhotoInputProps {
  value: string;
  label: string;
  onChange: (...event: unknown[]) => void;
}

const CustomPhotoInput: React.FC<CustomPhotoInputProps> = ({
  value,
  label,
  onChange,
}) => {
  const { error } = useNotification();
  const [photo, setPhoto] = useState(value || '');

  const loadPhoto = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (FileReader && event.target.files?.length) {
        const file = event.target.files[0];
        readFileAsDataUrl(file)
          .then((photoStr) => resizeImage(photoStr, 550))
          .then((photoStr) => {
            setPhoto(photoStr);
            // Call react-hook-form handler when photo changes
            onChange(photoStr);
          })
          .catch(error('Errore durante il caricamento della foto'));
      }
    },
    [error, onChange]
  );

  return (
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        component="label"
        role={undefined}
        variant="text"
        tabIndex={-1}
        endIcon={<PhotoIcon />}
      >
        {label}
        <VisuallyHiddenInput
          onChange={loadPhoto}
          type="file"
          accept="image/jpeg, image/jpg, image/png"
        />
      </Button>
      {photo.length ? (
        <Avatar
          alt="Foto"
          variant="square"
          sx={{ width: 56, height: 56 }}
          src={photo}
        />
      ) : (
        <Avatar>?</Avatar>
      )}
    </Stack>
  );
};

interface Props {
  name: string;
  label: string;
}

const PhotoInput: React.FC<Props> = ({ name, label }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CustomPhotoInput
          value={value as unknown as string}
          label={label}
          onChange={onChange}
        />
      )}
    />
  );
};

export default PhotoInput;

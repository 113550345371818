import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { itIT } from '@mui/x-data-grid/locales';

declare module '@mui/material/styles' {
  interface Palette {
    footer: Palette['primary'];
  }
  interface PaletteOptions {
    footer: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    footer: true;
  }
}

// A custom theme for this app
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
      footer: {
        main: grey[200],
      },
    },
  },
  itIT
);

export default theme;

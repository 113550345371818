import { useCallback, useEffect, useState } from 'react';

import { useEncryption } from '../../../lib/context/useEncryption';
import { useGoldenBook } from '../../../lib/context/useGoldenBook';
import { Award, GoldenBookEntry } from '../../../lib/types';
import { convertGrantNameToSingular } from '../../../lib/utils/string';
import GoldenBookTable, { GoldenBookTableRow } from './GoldenBookTable';

export type AwardsById = Record<number, Award>;

interface Props {
  awardsById: AwardsById;
}

const GoldenBookTableWrapper: React.FC<Props> = ({ awardsById }) => {
  const { entries } = useGoldenBook();
  const { isLocked } = useEncryption();
  const [tableRows, setTableRows] = useState<GoldenBookTableRow[]>([]);

  const getAwardName = useCallback(
    (entry: GoldenBookEntry): string => {
      if (entry.bestPresentationAward) {
        return 'MIGLIOR PRESENTAZIONE';
      } else {
        return entry.awardId
          ? convertGrantNameToSingular(awardsById[entry.awardId].name)
          : entry.otherAward ?? '';
      }
    },
    [awardsById]
  );

  const mapEntryToTableRow = useCallback(
    (entry: GoldenBookEntry): GoldenBookTableRow => ({
      id: entry.id,
      lastName: entry.awardee.lastName,
      firstName: entry.awardee.firstName,
      year: entry.year,
      awardName: getAwardName(entry),
      published: entry.published,
    }),
    [getAwardName]
  );

  const sortByFirstNameAndLastName = (
    rows: GoldenBookTableRow[]
  ): GoldenBookTableRow[] =>
    rows.sort(
      (a, b) =>
        a.lastName.localeCompare(b.lastName) ||
        a.firstName.localeCompare(b.firstName)
    );

  const removeDraftsIfUnencrypted = useCallback(
    (rows: GoldenBookTableRow[]) =>
      // Using `=== ttue` because published could be undefined
      rows.filter((row) => !(isLocked && row.published === false)),
    [isLocked]
  );

  useEffect(() => {
    if (Object.keys(awardsById).length > 0) {
      const rows = entries.map(mapEntryToTableRow);
      const sortedRows = sortByFirstNameAndLastName(rows);
      const filteredRows = removeDraftsIfUnencrypted(sortedRows);
      setTableRows(filteredRows);
    }
  }, [awardsById, entries, mapEntryToTableRow, removeDraftsIfUnencrypted]);

  return <GoldenBookTable rows={tableRows} />;
};

export default GoldenBookTableWrapper;

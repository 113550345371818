import FavoriteIcon from '@mui/icons-material/Favorite';
import {
  AppBar,
  Container,
  Link,
  SvgIcon,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

import { ReactComponent as CodeLoungeIcon } from '../../assets/images/horizontal-logo.svg';

const Footer = (): JSX.Element => {
  const theme = useTheme();

  return (
    <AppBar
      position="relative"
      elevation={0}
      variant="outlined"
      color="footer"
      sx={{
        top: 'auto',
        bottom: 0,
      }}
    >
      <Container maxWidth="md">
        <Toolbar>
          <Typography
            sx={{ width: '100%', textAlign: 'center', color: 'inherit' }}
            variant="body1"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            Developed with{' '}
            <FavoriteIcon sx={{ ...theme.typography.body2, mx: 0.5 }} /> by
            <Link
              href="https://codelounge.si.usi.ch/"
              target="_blank"
              rel="noreferrer"
              display="flex"
            >
              <SvgIcon
                sx={{
                  width: (theme) => theme.spacing(16),
                  height: '1.6em',
                  ml: 0.5,
                  fontSize: 'h5.fontSize',
                }}
                component={CodeLoungeIcon}
                viewBox="-50 189.4 398.1 122.6"
              />
            </Link>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;

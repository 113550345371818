import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Typography } from '@mui/material';

interface Props {
  refresh: () => void | Promise<void>;
}

const RefreshButton = ({ refresh }: Props): JSX.Element => (
  <Button
    size="small"
    aria-label="refresh"
    component="span"
    variant="text"
    color="info"
    onClick={refresh}
    endIcon={<RefreshIcon />}
  >
    <Typography variant="body2">Aggiorna</Typography>
  </Button>
);

export default RefreshButton;

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { SvgIconProps, Typography } from '@mui/material';
import { Box } from '@mui/system';

type IconFontSize = Exclude<SvgIconProps['fontSize'], undefined | 'inherit'>;

type PassthroughProps = Omit<SvgIconProps, 'color'>;
interface ExtraProps {
  rank: number;
  fontSize: IconFontSize;
  hideRankNumber?: boolean;
}

type Props = ExtraProps & PassthroughProps;
const PrizeIcon = ({
  rank,
  fontSize,
  hideRankNumber = false,
  ...props
}: Props) => {
  const isPodiumRank = rank >= 1 && rank <= 3;

  const getRankColor = (_rank: number): string => {
    if (_rank === 1) {
      return 'gold';
    } else if (_rank === 2) {
      return 'silver';
    } else if (_rank === 3) {
      return '#cd7f32';
    }
    // bronze'
    else {
      return 'whitesmoke';
    }
  };

  const fontSizeToTranslateMap: Record<IconFontSize, string> = {
    large: 'translate(-45%, -80%)',
    medium: 'translate(-47.5%, -75%)',
    small: 'translate(-50%, -77%)',
  };

  const fontSizeToScaleMap: Record<IconFontSize, string> = {
    large: 'scale(0.6, 0.6)',
    medium: 'scale(0.5, 0.5)',
    small: 'scale(0.45, 0.45)',
  };

  const color = getRankColor(rank);
  const numberColor = isPodiumRank ? 'white' : 'gray';
  const translate = fontSizeToTranslateMap[fontSize];
  const scale = fontSizeToScaleMap[fontSize];

  return (
    <Box position="relative">
      <EmojiEventsIcon sx={{ color }} fontSize={fontSize} {...props} />
      {!hideRankNumber && (
        <Typography
          component="span"
          sx={{
            position: 'absolute',
            textAlign: 'center',
            color: numberColor,
            top: '50%',
            left: '50%',
            transform: `${translate} ${scale}`,
            fontWeight: 'medium',
            fontSize: fontSize,
          }}
        >
          {rank}
        </Typography>
      )}
    </Box>
  );
};

export default PrizeIcon;

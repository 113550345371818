import { useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';

import { useGoldenBook } from '../../../lib/context/useGoldenBook';
import EncryptionKeyInputForm from './EncryptionKeyInputForm';

const UnlockEncryption: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { refreshEntries } = useGoldenBook();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    void refreshEntries();
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Accedi ai dati privati">
        <IconButton onClick={handleOpen}>
          <LockIcon color="error" />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Accedi ai dati privati
        </DialogTitle>
        <DialogContent>
          <EncryptionKeyInputForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UnlockEncryption;

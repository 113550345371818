import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import { AwardEdition, Candidacy, CandidacyWithIndex } from '../types';
import { useAwardEditions } from './useAwardEditions';

interface CandidaciesContextType {
  candidacies: CandidacyWithIndex[];
  selectedCandidacy?: Candidacy;
  selectCandidacyById: (id: number) => void;
  addCandidacy: (newCandidacy: Candidacy) => Promise<void>;
  editCandidacyIndex: (id: number, index: number) => Promise<void>;
  editCandidacy: (updatedCandidacy: Candidacy) => Promise<void>;
  deleteCandidacy: (id: number) => Promise<void>;
  refreshCandidacies: () => Promise<void>;
}

const CandidaciesContext = createContext<CandidaciesContextType | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}
export const CandidaciesProvider = ({ children }: Props): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();
  const value = useProvideCandidacies(selectedAwardEdition);
  return (
    <CandidaciesContext.Provider value={value}>
      {children}
    </CandidaciesContext.Provider>
  );
};

const useProvideCandidacies = (
  awardEdition: AwardEdition | undefined
): CandidaciesContextType => {
  const { error } = useNotification();
  const [candidacies, setCandidacies] = useState<CandidacyWithIndex[]>([]);
  const [selectedCandidacy, setSelectedCandidacy] = useState<
    Candidacy | undefined
  >();

  const selectCandidacyById = (id: number) =>
    setSelectedCandidacy(candidacies.find((candidacy) => candidacy.id === id));

  const fetchCandidacies = useCallback(async () => {
    if (awardEdition) {
      const newCandidacies = await apiClient.getCandidacies(awardEdition.id);
      const sortedCandidacies = newCandidacies.sort(
        (c1, c2) => c1.index - c2.index
      );
      setCandidacies(sortedCandidacies);
    }
  }, [awardEdition]);

  const addCandidacy = useCallback(
    async (newCandidacy: Candidacy) => {
      if (awardEdition) {
        await apiClient.postCandidacy(awardEdition.id, newCandidacy);
        await fetchCandidacies();
      }
    },
    [awardEdition, fetchCandidacies]
  );

  const editCandidacyIndex = useCallback(
    async (id: number, index: number) => {
      if (awardEdition) {
        await apiClient.putCandidacyIndex(awardEdition.id, { id, index });
        await fetchCandidacies();
      }
    },
    [awardEdition, fetchCandidacies]
  );

  const editCandidacy = useCallback(
    async (updatedCandidacy: Candidacy) => {
      await apiClient.putCandidacy(updatedCandidacy);
      await fetchCandidacies();
    },
    [fetchCandidacies]
  );

  const deleteCandidacy = useCallback(
    async (id: number) => {
      if (awardEdition) {
        await apiClient.deleteCandidacy(awardEdition.id, id);
        await fetchCandidacies();
      }
    },
    [awardEdition, fetchCandidacies]
  );

  useEffect(() => {
    if (awardEdition) {
      fetchCandidacies().catch(
        error('Errore durante lo scaricamento delle candidature.')
      );
    }

    return () => {
      setCandidacies([]);
      setSelectedCandidacy(undefined);
    };
  }, [awardEdition, error, fetchCandidacies]);

  return {
    candidacies,
    selectedCandidacy,
    selectCandidacyById,
    addCandidacy,
    editCandidacyIndex,
    editCandidacy,
    deleteCandidacy,
    refreshCandidacies: fetchCandidacies,
  };
};

export const useCandidacies = (): CandidaciesContextType => {
  const context = useContext(CandidaciesContext);

  if (context === undefined) {
    throw new Error('useCandidacies must be used within a CandidaciesProvider');
  }

  return context;
};

import { Button, Stack } from '@mui/material';

import { Link } from 'react-router-dom';

import LoginButton from '../components/common/auth/LoginButton';
import SplashWrapper from '../components/layout/SplashWrapper';
import { LOGO_SIZE } from '../config';

const LoginPage = (): JSX.Element => {
  return (
    <SplashWrapper>
      <Stack spacing={2} direction="row">
        <LoginButton />
        <Button
          size="large"
          color="secondary"
          variant="outlined"
          sx={{ width: LOGO_SIZE }}
        >
          <Link
            style={{ textDecoration: 'none', color: 'inherit ' }}
            to="/albodoro"
          >
            Albo d'oro
          </Link>
        </Button>
      </Stack>
    </SplashWrapper>
  );
};

export default LoginPage;

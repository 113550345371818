import { Container, Grid, Paper, Stack, Typography } from '@mui/material';

import { AwardEdition } from '../../lib/types';
import EditableCandidaciesList from '../common/lists/EditableCandidaciesList';
import JudgesList from '../common/lists/JudgesList';
import VotingControls from './VotingControls';

interface Props {
  awardEdition: AwardEdition;
}
const AwardEditionManager = ({ awardEdition }: Props): JSX.Element => {
  return (
    <Container sx={{ pt: 3, height: '100%' }}>
      <Grid container height="100%" direction="column" spacing={2}>
        <Grid item>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            flexWrap="nowrap"
          >
            <Typography variant="h5">{awardEdition.award.name}</Typography>
            <VotingControls />
          </Stack>
          <Typography
            variant="h6"
            color="gray"
            sx={{
              fontStyle: 'italic',
              fontWeight: 'regular',
            }}
          >
            {awardEdition.award.projectDescription}
          </Typography>
        </Grid>

        <Grid item container spacing={2} flex={1}>
          <Grid item flex={1} container direction="column">
            <Paper variant="outlined" sx={{ flex: '1 1 0', overflow: 'auto' }}>
              <JudgesList />
            </Paper>
          </Grid>

          <Grid item flex={1} container direction="column">
            <Paper variant="outlined" sx={{ flex: '1 1 0', overflow: 'auto' }}>
              <EditableCandidaciesList />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AwardEditionManager;

import { Navigate } from 'react-router-dom';

import { AwardEditionsProvider } from '../../../lib/context/useAwardEditions';
import { CandidaciesProvider } from '../../../lib/context/useCandidacies';
import { useUser } from '../../../lib/context/useUser';
import { getPageForRoles } from '../../../lib/roleToPageMapper';
import LoadingPage from '../../../pages/LoadingPage';

const VotingPageForRole: React.FC = () => {
  const { isLoading, user } = useUser();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  const Page = getPageForRoles(user.roles);

  return (
    <AwardEditionsProvider>
      <CandidaciesProvider>
        <Page />
      </CandidaciesProvider>
    </AwardEditionsProvider>
  );
};

export default VotingPageForRole;

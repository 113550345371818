import { ReactNode, useState } from 'react';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface Props extends Omit<ButtonProps, 'onClick'> {
  buttonLabel: string;
  dialogTitle: string;
  renderForm: (handleClose: () => void) => ReactNode;
}

const GenericFormDialogButton: React.FC<Props> = ({
  dialogTitle,
  buttonLabel,
  renderForm,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        aria-label={buttonLabel}
        variant="contained"
        color="success"
        onClick={handleOpen}
        {...buttonProps}
      >
        <Typography variant="body2">{buttonLabel}</Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{renderForm(handleClose)}</DialogContent>
      </Dialog>
    </>
  );
};

export default GenericFormDialogButton;

import { useEncryption } from '../../../lib/context/useEncryption';
import LockEncryption from './LockEncryption';
import UnlockEncryption from './UnlockEncryption';

const EncryptionLock: React.FC = () => {
  const { isLocked } = useEncryption();

  if (isLocked) {
    return <UnlockEncryption />;
  } else {
    return <LockEncryption />;
  }
};

export default EncryptionLock;

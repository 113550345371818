export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

export const DEPLOYMENT_ROOT_PATH = process.env.PUBLIC_URL || '';

export const FOUNDATION_NAME =
  process.env.REACT_APP_FOUNDATION_NAME || 'PREMIOSWISSENGINEERINGTICINO';

export const LOGO_SIZE = 300;

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

// IDs of awards that are grants, e.g. "BORSE DI STUDIO TALENTICINO (USI)"
export const GRANT_AWARD_IDS = [5, 6];

import { List, ListSubheader } from '@mui/material';

import { useAwardEditions } from '../../../lib/context/useAwardEditions';
import AwardEditionCard from '../cards/AwardEditionCard';
import RefreshButton from '../inputs/RefreshButton';

const AwardEditionsList = (): JSX.Element => {
  const {
    awardEditions,
    selectedAwardEdition,
    selectAwardEditionById,
    refreshAwardEditions,
  } = useAwardEditions();

  const awardEditionCards = awardEditions.map((awardEdition) => (
    <AwardEditionCard
      key={awardEdition.id}
      awardEdition={awardEdition}
      isSelected={awardEdition.id === selectedAwardEdition?.id}
      onClick={() => selectAwardEditionById(awardEdition.id)}
    />
  ));

  return (
    <List
      sx={{ width: '100%' }}
      aria-labelledby="award-editions-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="award-editions-list-subheader"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Premi
          <RefreshButton refresh={refreshAwardEditions} />
        </ListSubheader>
      }
    >
      {awardEditionCards}
    </List>
  );
};

export default AwardEditionsList;

import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useEncryption } from '../../../lib/context/useEncryption';
import ConfirmationDialogButton from '../../common/dialogs/ConfirmationDialogButton';

const LockEncryption: React.FC = () => {
  const { lock } = useEncryption();
  return (
    <ConfirmationDialogButton
      action={lock}
      dialog={{
        title: 'Nascondere i dati privati?',
        description:
          'Per visualizzare di nuovo i dati privati sarà necessario reinserire la chiave segreta.',
        okText: 'Nascondi dati',
      }}
      tooltip="Nascondi i dati privati"
      isIcon
    >
      <LockOpenIcon color="success" />
    </ConfirmationDialogButton>
  );
};

export default LockEncryption;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import { AwardEdition, Judge } from '../types';
import { useAwardEditions } from './useAwardEditions';

interface JudgesContextType {
  judges: Judge[];
  refreshJudges: () => Promise<void>;
}

const JudgesContext = createContext<JudgesContextType | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}
export const JudgesProvider = ({ children }: Props): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();
  const value = useProvideJudges(selectedAwardEdition);
  return (
    <JudgesContext.Provider value={value}>{children}</JudgesContext.Provider>
  );
};

const useProvideJudges = (
  awardEdition: AwardEdition | undefined
): JudgesContextType => {
  const { error } = useNotification();
  const [judges, setJudges] = useState<Judge[]>([]);

  const fetchJudges = useCallback(async () => {
    if (awardEdition) {
      const newJudges = await apiClient.getJudges(awardEdition.id);
      setJudges(newJudges);
    }
  }, [awardEdition]);

  useEffect(() => {
    if (awardEdition) {
      fetchJudges().catch(error('Errore durante lo scaricamento dei giudici.'));
    }

    return () => {
      setJudges([]);
    };
  }, [awardEdition, error, fetchJudges]);

  return {
    judges,
    refreshJudges: fetchJudges,
  };
};

export const useJudges = (): JudgesContextType => {
  const context = useContext(JudgesContext);

  if (context === undefined) {
    throw new Error('useJudges must be used within an JudgesProvider');
  }

  return context;
};

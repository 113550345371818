import { useCallback } from 'react';

import { Button, DialogActions, Grid } from '@mui/material';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useGoldenBook } from '../../../../lib/context/useGoldenBook';
import useNotification from '../../../../lib/hooks/useNotification';
import { GoldenBookAwardee, GoldenBookEntryInput } from '../../../../lib/types';
import TextInput from '../../../common/inputs/TextInput';

interface FormData {
  firstName: string;
  lastName: string;
}

interface Props {
  handleClose: () => void;
}

const AddGoldenBookEntryForm: React.FC<Props> = ({ handleClose }) => {
  const { error } = useNotification();
  const { addAwardee, addEntry } = useGoldenBook();

  const createAwardeeAndEntry = useCallback(
    async (data: FormData) => {
      // Add new Awardee
      const newAwardee: GoldenBookAwardee = {
        id: 0, // will be overridden in the backend
        lastName: data.lastName,
        firstName: data.firstName,
      };
      const { id: awardeeId } = await addAwardee(newAwardee);

      // Add new Entry
      const newEntry: GoldenBookEntryInput = {
        id: 0, // will be overridden in the backend
        awardeeId: `${awardeeId}`,
        year: `${new Date().getFullYear()}`,
        otherAward: 'DA CAMBIARE',
        published: false,
        bestPresentationAward: false,
      };
      await addEntry(newEntry);
    },
    [addAwardee, addEntry]
  );

  const onSubmit: SubmitHandler<FormData> = (data) => {
    createAwardeeAndEntry(data)
      .then(handleClose)
      .catch(error("Errore durante l'aggiunta del premiato"));
  };

  const formMethods = useForm<FormData>();

  const onCancel = () => {
    handleClose();
    formMethods.reset();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput name="lastName" label="Cognome" required />
          </Grid>
          <Grid item xs={6}>
            <TextInput name="firstName" label="Nome" required />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={onCancel}>Annulla</Button>
          <Button type="submit" autoFocus>
            Aggiungi
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default AddGoldenBookEntryForm;

import { ReactNode, useState } from 'react';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';

interface DialogProp {
  title: string;
  description: string;
  okText: string;
  cancelText?: string;
}
interface Props extends Omit<ButtonProps, 'onClick'> {
  action: () => void;
  tooltip: string;
  dialog: DialogProp;
  children: ReactNode;
  isIcon?: boolean;
}

const ConfirmationDialogButton: React.FC<Props> = ({
  action,
  tooltip,
  dialog: { title, description, okText, cancelText = 'Annulla' },
  children,
  isIcon = false,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = handleClose;
  const handleOk = () => {
    action();
    handleClose();
  };

  return (
    <>
      <Tooltip title={tooltip}>
        {isIcon ? (
          <IconButton onClick={handleClickOpen}>{children}</IconButton>
        ) : (
          <Button onClick={handleClickOpen} {...buttonProps}>
            {children}
          </Button>
        )}
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{cancelText}</Button>
          <Button onClick={handleOk} autoFocus>
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialogButton;

import { Typography } from '@mui/material';

interface Props {
  isPublic: boolean;
}

const PublishedText: React.FC<Props> = ({ isPublic }) => (
  <Typography variant="body1" color={isPublic ? 'success.main' : 'error.main'}>
    {isPublic ? 'Pubblicato' : 'Non pubblicato'}
  </Typography>
);

export default PublishedText;

import Box from '@mui/material/Box';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { useUser } from '../../../lib/context/useUser';
import EncryptionLock from '../lock/EncryptionLock';
import AddGoldenBookEntryDialogButton from './dialogs/AddGoldenBookEntryDialogButton';

const GoldenBookTableToolbar: React.FC = () => {
  const { isSecretary, isSecretaryOrBoardMember } = useUser();

  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
      <GridToolbarExport />
      {isSecretaryOrBoardMember && <Box sx={{ flexGrow: 1 }} />}
      {isSecretary && <AddGoldenBookEntryDialogButton />}
      {isSecretaryOrBoardMember && <EncryptionLock />}
    </GridToolbarContainer>
  );
};

export default GoldenBookTableToolbar;

import { Skeleton } from '@mui/material';

import { useGoldenBookEntry } from '../../../../lib/context/useGoldenBookEntry';
import { useUser } from '../../../../lib/context/useUser';
import AwardeePublicDataDisplay from '../display/AwardeePublicDataDisplay';

const AwardeePublicDataWrapper: React.FC = () => {
  const { isSecretary } = useUser();
  const { entry } = useGoldenBookEntry();

  return entry ? (
    <AwardeePublicDataDisplay
      awardee={entry.awardee}
      isSecretary={isSecretary}
    />
  ) : (
    <Skeleton variant="rounded" width="100%" height="100%" />
  );
};

export default AwardeePublicDataWrapper;

import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

interface Props<CheckboxValues extends FieldValues = FieldValues>
  extends Omit<CheckboxProps, 'name' | 'label'> {
  name: Path<CheckboxValues>;
  label: string;
}

const CheckboxInput = <CheckboxValues extends FieldValues = FieldValues>({
  name,
  label,
  ...switchProps
}: Props<CheckboxValues>): JSX.Element => {
  const { control } = useFormContext<CheckboxValues>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Checkbox checked={value} onChange={onChange} {...switchProps} />
          }
          label={label}
        />
      )}
    />
  );
};

export default CheckboxInput;

import { Container, Grid } from '@mui/material';

import AwardDataWrapper from './wrappers/AwardDataWrapper';
import AwardeePrivateDataWrapper from './wrappers/AwardeePrivateDataWrapper';
import AwardeePublicDataWrapper from './wrappers/AwardeePublicDataWrapper';

const EntryContainer: React.FC = () => (
  <Container
    maxWidth="lg"
    sx={{
      pt: 3,
      pb: 3,
      height: '100%',
      overflow: 'scroll',
    }}
  >
    <Grid container height="100%">
      <Grid item container direction="column" xs={6}>
        <Grid item padding={2}>
          <AwardeePublicDataWrapper />
        </Grid>
        <Grid item padding={2}>
          <AwardDataWrapper />
        </Grid>
      </Grid>
      <Grid item xs={6} height="100%" padding={2}>
        <AwardeePrivateDataWrapper />
      </Grid>
    </Grid>
  </Container>
);

export default EntryContainer;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import {
  GoldenBookAwardee,
  GoldenBookEntry,
  GoldenBookEntryInput,
} from '../types';
import { useUser } from './useUser';

interface GoldenBookContextType {
  entries: GoldenBookEntry[];
  addAwardee: (newAwardee: GoldenBookAwardee) => Promise<GoldenBookAwardee>;
  addEntry: (newEntry: GoldenBookEntryInput) => Promise<GoldenBookEntry>;
  refreshEntries: () => Promise<void>;
}

const GoldenBookContext = createContext<GoldenBookContextType | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}
export const GoldenBookProvider: React.FC<Props> = ({ children }) => {
  const value = useProvideEntries();
  return (
    <GoldenBookContext.Provider value={value}>
      {children}
    </GoldenBookContext.Provider>
  );
};

const useProvideEntries = (): GoldenBookContextType => {
  const { error } = useNotification();
  const { isSecretaryOrBoardMember } = useUser();
  const [entries, setEntries] = useState<GoldenBookEntry[]>([]);

  const fetchEntries = useCallback(async () => {
    const newEntries = await (isSecretaryOrBoardMember
      ? apiClient.getGoldenBookEntries()
      : apiClient.getPublicGoldenBookEntries());
    setEntries(newEntries);
  }, [isSecretaryOrBoardMember]);

  const addAwardee = useCallback(
    async (newAwardee: GoldenBookAwardee) => {
      const createdAwardee = await apiClient.postAwardee(newAwardee);
      await fetchEntries();
      return createdAwardee;
    },
    [fetchEntries]
  );

  const addEntry = useCallback(
    async (newEntry: GoldenBookEntryInput) => {
      const createdEntry = await apiClient.postGoldenBookEntry(newEntry);
      await fetchEntries();
      return createdEntry;
    },
    [fetchEntries]
  );

  useEffect(() => {
    fetchEntries().catch(
      error("Errore durante lo scaricamento dell Albo d'Oro.")
    );
  }, [error, fetchEntries]);

  return {
    entries,
    addAwardee,
    addEntry,
    refreshEntries: fetchEntries,
  };
};

export const useGoldenBook = (): GoldenBookContextType => {
  const context = useContext(GoldenBookContext);

  if (context === undefined) {
    throw new Error('useGoldenBook must be used within a GoldenBookProvider');
  }

  return context;
};

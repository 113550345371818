import JudgePage from '../pages/JudgePage';
import NoRolePage from '../pages/NoRolePage';
import SecretaryPage from '../pages/SecretaryPage';
import { Role } from './types';

export const getPageForRoles = (roles: Role[]): React.FC => {
  if (roles.includes(Role.judge)) {
    return JudgePage;
  } else if (roles.includes(Role.secretary)) {
    return SecretaryPage;
  } else {
    return NoRolePage;
  }
};

import { useMemo } from 'react';

import { Skeleton } from '@mui/material';

import { GRANT_AWARD_IDS } from '../../../../config';
import { useGoldenBookEntry } from '../../../../lib/context/useGoldenBookEntry';
import { useUser } from '../../../../lib/context/useUser';
import AwardDataDisplay from '../display/AwardDataDisplay';

const AwardDataWrapper: React.FC = () => {
  const { isSecretary } = useUser();
  const { entry } = useGoldenBookEntry();

  const isGrantAward = useMemo(
    () => GRANT_AWARD_IDS.includes(entry?.awardId || -1),
    [entry?.awardId]
  );

  return entry ? (
    <AwardDataDisplay
      entry={entry}
      isSecretary={isSecretary}
      isGrantAward={isGrantAward}
    />
  ) : (
    <Skeleton variant="rounded" width="100%" height="100%" />
  );
};

export default AwardDataWrapper;

import { useLocalStorage } from './useLocalStorage';

export const STORAGE_KEY = 'candidacyNotes';
type Notes = Record<number, Record<number, string>>;

interface ReturnType {
  readNotes: (
    awardEditionId: number,
    candidacyId: number
  ) => string | undefined;
  writeNotes: (
    awardEditionId: number,
    candidacyId: number,
    notes: string
  ) => void;
}
export const useCandidacyNotes = (): ReturnType => {
  const { readValue, writeValue } = useLocalStorage<Notes>(STORAGE_KEY);

  const readNotes = (
    awardEditionId: number,
    candidacyId: number
  ): string | undefined => {
    const v = readValue();
    return v && v[awardEditionId] && v[awardEditionId][candidacyId];
  };

  const writeNotes = (
    awardEditionId: number,
    candidacyId: number,
    notes: string
  ): void => {
    const storedNotes = readValue() || {};
    const awardEditionNotes = storedNotes[awardEditionId] || {};
    awardEditionNotes[candidacyId] = notes;
    storedNotes[awardEditionId] = awardEditionNotes;
    writeValue(storedNotes);
  };

  return {
    readNotes,
    writeNotes,
  };
};

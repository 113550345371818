import { List, ListSubheader } from '@mui/material';

import { useCandidacies } from '../../../lib/context/useCandidacies';
import CandidacyCard from '../cards/CandidacyCard';
import RefreshButton from '../inputs/RefreshButton';

const CandidaciesList = (): JSX.Element => {
  const {
    candidacies,
    selectedCandidacy,
    selectCandidacyById,
    refreshCandidacies,
  } = useCandidacies();
  const candidacyCards = candidacies.map((candidacy) => (
    <CandidacyCard
      key={candidacy.id}
      candidacy={candidacy}
      isSelected={candidacy.id === selectedCandidacy?.id}
      onClick={() => selectCandidacyById(candidacy.id)}
    />
  ));

  return (
    <List
      sx={{ width: '100%' }}
      aria-labelledby="candidacies-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="candidacies-list-subheader"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Candidature
          <RefreshButton refresh={refreshCandidacies} />
        </ListSubheader>
      }
    >
      {candidacyCards}
    </List>
  );
};

export default CandidaciesList;

import { Grid } from '@mui/material';

import GoldenBookContainer from '../../components/goldenbook/GoldenBookContainer';
import Wrapper from '../../components/layout/Wrapper';
import { GoldenBookProvider } from '../../lib/context/useGoldenBook';

const GoldenBookPage: React.FC = () => (
  <GoldenBookProvider>
    <Wrapper>
      <Grid container height="100%">
        <Grid item height="100%" overflow="auto" xs={12}>
          <GoldenBookContainer />
        </Grid>
      </Grid>
    </Wrapper>
  </GoldenBookProvider>
);

export default GoldenBookPage;

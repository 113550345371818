import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Container, TextField } from '@mui/material';

import { useCandidacyNotes } from '../../lib/hooks/useCandidacyNotes';
import { AwardEdition, Candidacy } from '../../lib/types';

interface Props {
  awardEdition: AwardEdition;
  candidacy: Candidacy;
  disabled?: boolean;
}
const CandidacyNotes = ({
  awardEdition,
  candidacy,
  disabled = false,
}: Props): JSX.Element => {
  const { readNotes, writeNotes } = useCandidacyNotes();
  const [notes, setNotes] = useState<string>('');

  // On component load, read stored notes
  useEffect(() => {
    const storedNotes = readNotes(awardEdition.id, candidacy.id);
    if (storedNotes) {
      setNotes(storedNotes);
    } else {
      setNotes('');
    }
  }, [awardEdition.id, candidacy.id, readNotes]);

  // Update local and stored notes on change
  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;
      writeNotes(awardEdition.id, candidacy.id, text);
      setNotes(text);
    },
    [awardEdition.id, candidacy.id, writeNotes]
  );

  return (
    <Container component="main" sx={{ pb: 2 }}>
      <TextField
        label="Note personali"
        multiline
        minRows={4}
        disabled={disabled}
        value={notes}
        onChange={onChangeHandler}
        sx={{ width: '100%' }}
      />
    </Container>
  );
};

export default CandidacyNotes;

import { ComponentType } from 'react';

import Fade from '@mui/material/Fade';

import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';

import BoardMembersRoutes from './components/common/routing/BoardMembersRoutes';
import PrivateRoutes from './components/common/routing/PrivateRoutes';
import RedirectToPageBasedOnRole from './components/common/routing/RedirectToPageBasedOnRole';
import VotingPageForRole from './components/common/routing/VotingPageForRole';
import GoldenBookEntryPage from './pages/GoldenBookEntryPage';
import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/public/ErrorPage';
import GoldenBookPage from './pages/public/GoldenBookPage';

const App = (): JSX.Element => {
  return (
    <SnackbarProvider
      maxSnack={5}
      TransitionComponent={Fade as ComponentType}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      dense
    >
      <Routes>
        <Route
          path="/"
          element={<RedirectToPageBasedOnRole />}
          errorElement={<ErrorPage />}
        />
        <Route element={<PrivateRoutes />} errorElement={<ErrorPage />}>
          <Route
            path="/voto"
            element={<VotingPageForRole />}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route element={<BoardMembersRoutes />} errorElement={<ErrorPage />}>
          <Route
            path="/albodoro/:entryId"
            element={<GoldenBookEntryPage />}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route
          path="/login"
          element={<LoginPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/albodoro"
          element={<GoldenBookPage />}
          errorElement={<ErrorPage />}
        />
      </Routes>
    </SnackbarProvider>
  );
};

export default App;

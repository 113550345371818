import { useEffect, useState } from 'react';

import { Container, Typography } from '@mui/material';

import apiClient from '../../lib/apiClient';
import useNotification from '../../lib/hooks/useNotification';
import GoldenBookTableWrapper, {
  AwardsById,
} from './table/GoldenBookTableWrapper';

const GoldenBookContainer: React.FC = () => {
  const { error } = useNotification();
  const [awardsById, setAwardsById] = useState<AwardsById>({});

  useEffect(() => {
    apiClient
      .getPublicAwards()
      .then((awards) =>
        Object.fromEntries(awards.map((award) => [award.id, award]))
      )
      .then(setAwardsById)
      .catch(error('Errore durante lo scaricamento dei premi.'));
  }, [error]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        pt: 3,
        pb: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography align="center" variant="h2">
        Albo d'Oro
      </Typography>
      <GoldenBookTableWrapper awardsById={awardsById} />
    </Container>
  );
};

export default GoldenBookContainer;

import { ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import logo from '../../assets/images/logo.png';
import { FOUNDATION_NAME, LOGO_SIZE } from '../../config';

interface Props {
  children: ReactNode;
}

const SplashWrapper = ({ children }: Props): JSX.Element => (
  <Grid
    container
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    spacing={3}
    sx={{ minHeight: '100vh' }}
  >
    <Grid item>
      <Box
        component="img"
        src={logo}
        alt="Logo PremioSE"
        sx={{ width: LOGO_SIZE, height: LOGO_SIZE }}
      />
    </Grid>
    <Grid item textAlign="center">
      <Typography variant="h3">Fondazione</Typography>
      <Typography variant="h3">{FOUNDATION_NAME}</Typography>
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);

export default SplashWrapper;

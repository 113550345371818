import { GoldenBookAwardee } from '../../../../lib/types';
import GenericFormDialogButton from '../../../common/dialogs/GenericFormDialogButton';
import EditAwardeePublicDataForm from '../forms/EditAwardeePublicDataForm';

interface Props {
  awardee: GoldenBookAwardee;
}

const EditAwardeePublicDataDialogButton: React.FC<Props> = ({ awardee }) => (
  <GenericFormDialogButton
    color="info"
    variant="text"
    buttonLabel="Modifica"
    dialogTitle="Modifica dati pubblici"
    renderForm={(handleClose) => (
      <EditAwardeePublicDataForm awardee={awardee} handleClose={handleClose} />
    )}
  />
);

export default EditAwardeePublicDataDialogButton;

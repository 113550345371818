import { List, ListSubheader } from '@mui/material';

import { useAwardEditions } from '../../../lib/context/useAwardEditions';
import { useCandidacies } from '../../../lib/context/useCandidacies';
import { AwardEditionVotingState as VotingState } from '../../../lib/types';
import EditableCandidacyCard from '../cards/EditableCandidacyCard';
import AddCandidacyDialogButton from '../dialogs/AddCandidacyDialogButton';

const EditableCandidaciesList = (): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();
  const { candidacies, editCandidacyIndex } = useCandidacies();

  const possibleIndices = Array.from(
    { length: candidacies.length },
    (_, i) => i + 1
  );

  const editableCandidacyCards = candidacies.map((candidacy) => (
    <EditableCandidacyCard
      key={candidacy.id}
      candidacy={candidacy}
      editIndex={(newIndex: number) =>
        editCandidacyIndex(candidacy.id, newIndex)
      }
      possibleIndices={possibleIndices}
    />
  ));

  const addButton = (
    <AddCandidacyDialogButton
      withProjectTitle={
        selectedAwardEdition?.award.withProjectCandidacies ?? false
      }
    />
  );

  return (
    <List
      sx={{ width: '100%' }}
      aria-labelledby="editable-candidacies-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="editable-candidacies-list-subheader"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Candidature
          {selectedAwardEdition?.votingState === VotingState.EDITION_OPEN &&
            addButton}
        </ListSubheader>
      }
    >
      {editableCandidacyCards}
    </List>
  );
};

export default EditableCandidaciesList;

import { Button } from '@mui/material';

import { LOGO_SIZE } from '../../../config';
import { useUser } from '../../../lib/context/useUser';

interface Props {
  white?: boolean;
}

const LoginButton: React.FC<Props> = ({ white = false }) => {
  const { login } = useUser();
  return (
    <Button
      size="large"
      color={white ? 'inherit' : 'primary'}
      variant="outlined"
      sx={white ? {} : { width: LOGO_SIZE }}
      onClick={() => login()}
    >
      Login
    </Button>
  );
};

export default LoginButton;

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import { AwardeePrivateData } from '../../../../lib/types';
import { defaultProPicDataUrlStr } from '../../../../lib/utils/propic';
import EditAwardeePrivateDataDialogButton from '../dialogs/EditAwardeePrivateDataDialogButton';
import LabeledStringDisplay from './LabeledStringDisplay';

interface Props {
  awardeePrivateData: AwardeePrivateData;
  isSecretary: boolean;
}

const AwardeePrivateDataDisplay: React.FC<Props> = ({
  awardeePrivateData: data,
  isSecretary,
}) => (
  <Card variant="outlined">
    <CardMedia
      component="img"
      sx={{ height: '100%', objectFit: 'contain' }}
      src={data.photo ?? defaultProPicDataUrlStr}
      title="foto premiato"
    />
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" mb={2}>
        Dati privati
      </Typography>
      <LabeledStringDisplay label="Email" value={data.email} />
      <LabeledStringDisplay label="Numero di telefono" value={data.phone} />
      <LabeledStringDisplay label="Anno di nascita" value={data.birthYear} />
      <LabeledStringDisplay label="Indirizzo" value={data.address} />
      <LabeledStringDisplay label="Città" value={data.city} />
    </CardContent>
    {isSecretary && (
      <CardActions>
        <EditAwardeePrivateDataDialogButton awardeePrivateData={data} />
      </CardActions>
    )}
  </Card>
);

export default AwardeePrivateDataDisplay;

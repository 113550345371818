import { useState } from 'react';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Logout from '@mui/icons-material/Logout';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { Link } from 'react-router-dom';

import { IS_DEVELOPMENT } from '../../config';
import { useUser } from '../../lib/context/useUser';
import genKeys from '../../lib/utils/genKeys';
import LoginButton from '../common/auth/LoginButton';

const UserMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useUser();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return <LoginButton white />;
  }

  const avatar = (
    <IconButton onClick={handleOpenUserMenu} sx={{ ml: 1 }}>
      <Avatar>
        {user?.firstName[0]}
        {user?.lastName[0]}
      </Avatar>
    </IconButton>
  );

  const name = (
    <Typography variant="h6" noWrap component="div">
      {user?.firstName}
    </Typography>
  );

  const userMenu = (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem onClick={() => logout()}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
      {IS_DEVELOPMENT && (
        <MenuItem onClick={genKeys}>
          <ListItemIcon>Genera chiavi</ListItemIcon>
        </MenuItem>
      )}
      <MenuItem>
        <ListItemIcon>
          <EmojiEventsIcon />
        </ListItemIcon>
        <Link
          to="/albodoro"
          style={{ textDecoration: 'none', color: 'inherit ' }}
        >
          Albo d'Oro
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {name}
      {avatar}
      {userMenu}
    </>
  );
};

export default UserMenu;

// Inspiration: https://usehooks-typescript.com/react-hook/use-local-storage

type ReturnType<T> = {
  readValue: () => T | undefined;
  writeValue: (value: T) => void;
  clearValue: () => void;
};

export const useLocalStorage = <T>(key: string): ReturnType<T> => {
  const readValue = () => {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : undefined;
  };

  const writeValue = (value: T) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const clearValue = (): void => {
    window.localStorage.removeItem(key);
  };

  return { readValue, writeValue, clearValue };
};

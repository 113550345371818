import { Grid } from '@mui/material';

import AwardEditionsList from '../components/common/lists/AwardEditionsList';
import Wrapper from '../components/layout/Wrapper';
import AwardEditionManagerWrapper from '../components/secretary/AwardEditionManagerWrapper';
import { JudgesProvider } from '../lib/context/useJudges';

const SecretaryPage: React.FC = () => (
  <Wrapper>
    <JudgesProvider>
      <Grid container height="100%">
        <Grid
          item
          height="100%"
          overflow="auto"
          xs={3}
          sx={{ borderRight: '0.5px solid lightgrey' }}
        >
          <AwardEditionsList />
        </Grid>
        <Grid item height="100%" overflow="auto" xs={9}>
          <AwardEditionManagerWrapper />
        </Grid>
      </Grid>
    </JudgesProvider>
  </Wrapper>
);

export default SecretaryPage;

import { Button, DialogActions, Grid } from '@mui/material';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useCandidacies } from '../../../lib/context/useCandidacies';
import useNotification from '../../../lib/hooks/useNotification';
import { Candidacy } from '../../../lib/types';
import TextInput from '../inputs/TextInput';

interface FormData {
  firstName1: string;
  lastName1: string;
  firstName2?: string;
  lastName2?: string;
  projectTitle?: string;
  diplomaTitle?: string;
}

interface Props {
  withProjectTitle: boolean;
  handleClose: () => void;
}

const AddCandidacyForm = ({
  withProjectTitle,
  handleClose,
}: Props): JSX.Element => {
  const { error } = useNotification();
  const { addCandidacy } = useCandidacies();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const candidate1 = { lastName: data.lastName1, firstName: data.firstName1 };
    const candidate2 =
      data.lastName2 && data.firstName2
        ? {
            lastName: data.lastName2,
            firstName: data.firstName2,
          }
        : undefined;
    const newCandidacy: Candidacy = {
      id: 0, // will be overridden in the backend
      candidates: [candidate1, ...(candidate2 ? [candidate2] : [])],
      projectTitle: data.projectTitle,
      diplomaTitle: data.diplomaTitle,
    };
    addCandidacy(newCandidacy)
      .then(handleClose)
      .catch(error("Errore durante l'aggiunta della candidatura"));
  };

  const formMethods = useForm<FormData>();

  const onCancel = () => {
    handleClose();
    formMethods.reset();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput name="lastName1" label="Cognome" required />
          </Grid>
          <Grid item xs={6}>
            <TextInput name="firstName1" label="Nome" required />
          </Grid>
          <Grid item xs={6}>
            <TextInput name="lastName2" label="Cognome" />
          </Grid>
          <Grid item xs={6}>
            <TextInput name="firstName2" label="Nome" />
          </Grid>
          {withProjectTitle && (
            <Grid item xs={12}>
              <TextInput name="projectTitle" label="Titolo progetto" required />
            </Grid>
          )}
          {withProjectTitle && (
            <Grid item xs={12}>
              <TextInput name="diplomaTitle" label="Diploma" />
            </Grid>
          )}
        </Grid>
        <DialogActions>
          <Button onClick={onCancel}>Annulla</Button>
          <Button type="submit" autoFocus>
            Aggiungi
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default AddCandidacyForm;

import { Navigate } from 'react-router-dom';

import { useUser } from '../../../lib/context/useUser';
import { Role } from '../../../lib/types';
import LoadingPage from '../../../pages/LoadingPage';

const RedirectToPageBasedOnRole: React.FC = () => {
  const { isLoading, user } = useUser();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  const { roles } = user;

  if (roles.includes(Role.judge) || roles.includes(Role.secretary)) {
    return <Navigate to="/voto" />;
  }

  if (roles.includes(Role.boardmember)) {
    return <Navigate to="/albodoro" />;
  }

  return <Navigate to="/albodoro" />;
};

export default RedirectToPageBasedOnRole;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import apiClient from '../apiClient';
import useNotification from '../hooks/useNotification';
import {
  AwardEdition,
  CriterionGradeInput,
  Grade,
  AwardEditionJudgeVotingState as VotingState,
  WeightedCriterion,
} from '../types';
import { useAwardEditions } from './useAwardEditions';

type GradeByCriterionIdMap = Record<number, Grade>;
type JudgeGradesByCandidacyIdMap = Record<
  number,
  GradeByCriterionIdMap | undefined
>;

interface JudgeGradesContextType {
  weightedCriteria: WeightedCriterion[];
  judgeGradesByCandidacyId: JudgeGradesByCandidacyIdMap;
  saveGradeOfCandidacyById: (
    candidacyId: number,
    gradeForCriterion: CriterionGradeInput
  ) => void;
  votingState: VotingState;
  refreshJudgeGrades: () => Promise<void>;
}

const JudgeGradesContext = createContext<JudgeGradesContextType | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}
export const JudgeGradesProvider = ({ children }: Props): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();
  const value = useProvideJudgeGrades(selectedAwardEdition);
  return (
    <JudgeGradesContext.Provider value={value}>
      {children}
    </JudgeGradesContext.Provider>
  );
};

const useProvideJudgeGrades = (
  awardEdition: AwardEdition | undefined
): JudgeGradesContextType => {
  const { error, warning } = useNotification();
  const [weightedCriteria, setWeightedCriteria] = useState<WeightedCriterion[]>(
    []
  );
  const [judgeGradesByCandidacyId, setJudgeGradesByCandidacyId] =
    useState<JudgeGradesByCandidacyIdMap>({});
  const [votingState, setVotingState] = useState<VotingState>(
    VotingState.VOTING_NOT_STARTED
  );

  const fetchWeightedCriteria = useCallback(
    (awardEditionId: number) => {
      apiClient
        .getCriteria(awardEditionId)
        .then(setWeightedCriteria)
        .catch(error('Errore durante lo scaricamento dei criteri.'));
    },
    [error]
  );

  useEffect(() => {
    if (awardEdition) {
      fetchWeightedCriteria(awardEdition.id);
    }
  }, [awardEdition, fetchWeightedCriteria]);

  const fetchJudgeGrades = useCallback(async () => {
    if (awardEdition) {
      const { judgeGrades, judgeVotingState } =
        await apiClient.getJudgeGradesAndStatus(awardEdition.id);

      const gradesMap: JudgeGradesByCandidacyIdMap = judgeGrades.reduce(
        (candidacyIdMap, judgeGrade) => ({
          ...candidacyIdMap,
          [judgeGrade.candidacyId]: judgeGrade.grades.reduce(
            (criterionIdMap, grade) => ({
              ...criterionIdMap,
              [grade.criterion.id]: grade.grade,
            }),
            {}
          ),
        }),
        {}
      );

      console.log(gradesMap);
      setVotingState(judgeVotingState);
      setJudgeGradesByCandidacyId(gradesMap);
    }
  }, [awardEdition]);

  useEffect(() => {
    if (awardEdition) {
      fetchJudgeGrades().catch(
        error('Errore durante lo scaricamento dei voti.')
      );
    }

    return () => {
      setJudgeGradesByCandidacyId([]);
    };
  }, [awardEdition, error, fetchJudgeGrades]);

  const saveGradeOfCandidacyById = useCallback(
    (candidacyId: number, gradeForCriterion: CriterionGradeInput) => {
      if (votingState !== VotingState.VOTING_ONGOING) {
        warning(`Voto già completato.`);
        return;
      }

      const postJudgeGrade = async (
        awardEditionId: number,
        gradeInput: CriterionGradeInput
      ) => {
        const resultingJudgeGrade = await apiClient.putJudgeGrade(
          awardEditionId,
          {
            candidacyId,
            grades: [gradeInput],
          }
        );

        setJudgeGradesByCandidacyId((previous) => ({
          ...previous,
          [resultingJudgeGrade.candidacyId]: resultingJudgeGrade.grades.reduce(
            (map, grade) => ({
              ...map,
              [grade.criterion.id]: grade.grade,
            }),
            {}
          ),
        }));
      };

      if (awardEdition && gradeForCriterion.grade) {
        postJudgeGrade(awardEdition.id, gradeForCriterion).catch(
          error("Errore durante l'invio dei voti.")
        );
      }
    },
    [awardEdition, error, warning, votingState]
  );

  return {
    weightedCriteria,
    judgeGradesByCandidacyId,
    saveGradeOfCandidacyById,
    votingState,
    refreshJudgeGrades: fetchJudgeGrades,
  };
};

export const useJudgeGrades = (): JudgeGradesContextType => {
  const context = useContext(JudgeGradesContext);

  if (context === undefined) {
    throw new Error('useJudgeGrades must be used within a JudgeGradesProvider');
  }

  return context;
};

import { log } from './log';

export const resizeImage = (
  imageB64Str: string,
  maxWidth: number
): Promise<string> =>
  new Promise((resolve) => {
    log('Resizing image...');
    const img = document.createElement('img');
    img.src = imageB64Str;

    img.onload = (e: Event) => {
      const canvas = document.createElement('canvas');
      const ratio = maxWidth / (e.target as HTMLImageElement).width;

      canvas.width = maxWidth;
      canvas.height = (e.target as HTMLImageElement).height * ratio;

      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.drawImage(img, 0, 0, canvas.width, canvas.height);

      const resizedImageB64Str = context.canvas.toDataURL('image/jpg', 100);
      log('Resized image.');
      resolve(resizedImageB64Str);
    };
  });

import GenericFormDialogButton from '../../../common/dialogs/GenericFormDialogButton';
import AddGoldenBookEntryForm from '../forms/AddGoldenBookEntryForm';

const AddGoldenBookEntryDialogButton: React.FC = () => (
  <GenericFormDialogButton
    buttonLabel="Aggiungi"
    dialogTitle="Aggiungi premiato"
    renderForm={(handleClose) => {
      return <AddGoldenBookEntryForm handleClose={handleClose} />;
    }}
  />
);

export default AddGoldenBookEntryDialogButton;

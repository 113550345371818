import { MouseEventHandler, ReactNode } from 'react';

import {
  Avatar,
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

type ColorString =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

export interface BadgeSpec {
  color: ColorString;
  content: string;
}
interface GenericCardProps {
  title: string;
  subtitle?: ReactNode;
  isSelected?: boolean;
  icon?: JSX.Element;
  onClick?: MouseEventHandler;
  badge?: BadgeSpec;
  secondaryAction?: ReactNode;
}

export type GenericCardInteractionProps = Pick<
  GenericCardProps,
  'onClick' | 'isSelected' | 'badge'
>;

const GenericCard = ({
  title,
  subtitle,
  isSelected = false,
  icon,
  onClick,
  badge,
  secondaryAction,
}: GenericCardProps): JSX.Element => {
  const avatar =
    icon && badge ? (
      <Badge
        badgeContent={badge.content}
        color={badge.color}
        overlap="circular"
      >
        <Avatar>{icon}</Avatar>
      </Badge>
    ) : (
      <Avatar>{icon}</Avatar>
    );

  return (
    <ListItem secondaryAction={secondaryAction} disablePadding>
      <ListItemButton onClick={onClick} selected={isSelected} divider>
        {icon && <ListItemAvatar>{avatar}</ListItemAvatar>}
        <ListItemText primary={title} secondary={subtitle} />
      </ListItemButton>
    </ListItem>
  );
};

export default GenericCard;

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { Grade, WeightedCriterion } from '../../../lib/types';

interface Props {
  weightedCriterion: WeightedCriterion;
  disabled?: boolean;
  grade?: Grade;
  setGrade: (grade: Grade) => void;
}

const GradeInput = ({
  weightedCriterion,
  disabled = false,
  grade,
  setGrade,
}: Props): JSX.Element => {
  const possibleGrades: Grade[] = [6, 7, 8, 9, 10];

  const handleRadioChange = (value: string) => {
    setGrade(Number.parseInt(value) as Grade);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl sx={{ mt: 3, width: '100%' }} component="fieldset">
        <FormLabel sx={{ mb: 2 }} component="legend">
          <Typography display="inline">
            {weightedCriterion.criterion.description}{' '}
          </Typography>
          <Typography display="inline" fontWeight="medium">
            (peso: {weightedCriterion.weight}x)
          </Typography>
        </FormLabel>
        <RadioGroup
          sx={{ justifyContent: 'space-between' }}
          row
          aria-label={weightedCriterion.criterion.description}
          name="radio-buttons-group"
          value={grade || null}
          onChange={(_, value) => handleRadioChange(value)}
        >
          {possibleGrades.map((possibleGrade) => (
            <FormControlLabel
              key={`${weightedCriterion.criterion.id}-${possibleGrade}`}
              disabled={disabled}
              value={possibleGrade}
              control={<Radio />}
              label={possibleGrade}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default GradeInput;

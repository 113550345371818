import { AppBar, Avatar, Toolbar, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import { FOUNDATION_NAME } from '../../config';
import UserMenu from '../menu/UserMenu';

const TopBar = (): JSX.Element => (
  <AppBar position="relative" variant="outlined" elevation={0}>
    <Toolbar>
      <Avatar alt="Logo PremioSE" src={logo} />
      <Typography
        variant="h5"
        noWrap
        component="div"
        sx={{ flexGrow: 1, ml: 2 }}
      >
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit ' }}>
          Fondazione {FOUNDATION_NAME}
        </Link>
      </Typography>
      <UserMenu />
    </Toolbar>
  </AppBar>
);

export default TopBar;

import { useEffect, useRef, useState } from 'react';

import PrintIcon from '@mui/icons-material/Print';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useReactToPrint } from 'react-to-print';

import apiClient from '../../lib/apiClient';
import useNotification from '../../lib/hooks/useNotification';
import { AwardEdition, Ranking } from '../../lib/types';
import RankingList from './ranking/RankingList';

interface Props {
  awardEdition: AwardEdition;
}

const AwardEditionRanking = ({ awardEdition }: Props): JSX.Element => {
  const { error } = useNotification();
  const [ranking, setRanking] = useState<Ranking | undefined>();
  const componentToPrint = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
    removeAfterPrint: true,
    pageStyle: `
      @media print {
        body { 
          -webkit-print-color-adjust: exact; 
        }
        .ranking-list-item {
          break-inside: avoid;
        }
      }

      #print-button {
        display: none;
      }
    `,
  });

  useEffect(() => {
    apiClient
      .getRanking(awardEdition.id)
      .then(setRanking)
      .catch(error('Errore durante lo scaricamento della classifica.'));
  }, [awardEdition, error]);

  return (
    <Container sx={{ pt: 3 }}>
      <Box ref={componentToPrint}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          <Typography variant="h5">{awardEdition.award.name}</Typography>
          <Button
            id="print-button"
            variant="contained"
            onClick={handlePrint}
            endIcon={<PrintIcon />}
            sx={{ flexShrink: 0 }}
          >
            Stampa
          </Button>
        </Stack>
        <Typography
          variant="h6"
          color="gray"
          sx={{
            fontStyle: 'italic',
            fontWeight: 'regular',
          }}
        >
          {awardEdition.award.projectDescription}
        </Typography>
        <Typography variant="h6" color="gray" fontWeight="normal" pt={3}>
          Classifica
        </Typography>
        {ranking && <RankingList entries={ranking.ranking} />}
        {ranking?.presentationRanking && (
          <Typography variant="h6" color="gray" fontWeight="normal" pt={3}>
            Classifica presentazioni
          </Typography>
        )}
        {ranking?.presentationRanking && (
          <RankingList entries={ranking.presentationRanking} />
        )}
      </Box>
    </Container>
  );
};

export default AwardEditionRanking;

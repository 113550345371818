import { List, ListSubheader } from '@mui/material';

import { useJudges } from '../../../lib/context/useJudges';
import JudgeCard from '../cards/JudgeCard';
import RefreshButton from '../inputs/RefreshButton';

const JudgesList = (): JSX.Element => {
  const { judges, refreshJudges } = useJudges();

  const judgeCards = judges.map((judge) => (
    <JudgeCard key={judge.judgeKeycloakId} judge={judge} />
  ));

  return (
    <List
      sx={{ width: '100%' }}
      aria-labelledby="judges-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="judges-list-subheader"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Giudici
          <RefreshButton refresh={refreshJudges} />
        </ListSubheader>
      }
    >
      {judgeCards}
    </List>
  );
};

export default JudgesList;

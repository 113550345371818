import PersonIcon from '@mui/icons-material/Person';

import { Candidacy } from '../../../lib/types';
import GenericCard, { GenericCardInteractionProps } from './GenericCard';

interface Props extends GenericCardInteractionProps {
  candidacy: Candidacy;
}

const CandidacyCard = ({ candidacy, ...props }: Props): JSX.Element => {
  const names = candidacy.candidates
    .map((c) => `${c.lastName} ${c.firstName}`)
    .join(', ');

  const subtitle = (
    <>
      {candidacy.diplomaTitle?.trim() && (
        <>
          {candidacy.diplomaTitle?.trim()} <br />
        </>
      )}
      {candidacy.projectTitle || ''}
    </>
  );
  return (
    <GenericCard
      title={names}
      subtitle={subtitle}
      icon={<PersonIcon />}
      {...props}
    />
  );
};

export default CandidacyCard;

import { useMemo } from 'react';

import { Button, DialogActions, Grid, InputProps } from '@mui/material';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useGoldenBookEntry } from '../../../../lib/context/useGoldenBookEntry';
import { useUser } from '../../../../lib/context/useUser';
import useNotification from '../../../../lib/hooks/useNotification';
import { GoldenBookAwardee } from '../../../../lib/types';
import TextInput from '../../../common/inputs/TextInput';

interface FormData {
  firstName: string;
  lastName: string;
}

interface Props {
  handleClose: () => void;
  awardee: GoldenBookAwardee;
}

const EditAwardeePublicDataForm: React.FC<Props> = ({
  awardee,
  handleClose,
}) => {
  const { error } = useNotification();
  const { editAwardee } = useGoldenBookEntry();
  const { isSecretary } = useUser();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const updatedAwardee: GoldenBookAwardee = {
      ...awardee,
      firstName: data.firstName,
      lastName: data.lastName,
    };
    editAwardee(updatedAwardee)
      .then(handleClose)
      .catch(error('Errore durante la modifica del premiato'));
  };

  const defaultValues: FormData = {
    firstName: awardee.firstName,
    lastName: awardee.lastName,
  };

  const formMethods = useForm<FormData>({ defaultValues });

  const onCancel = () => {
    handleClose();
    formMethods.reset();
  };

  const readOnly: Partial<InputProps> = useMemo(
    () => ({ readOnly: !isSecretary }),
    [isSecretary]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput InputProps={readOnly} name="lastName" label="Cognome" />
          </Grid>
          <Grid item xs={6}>
            <TextInput InputProps={readOnly} name="firstName" label="Nome" />
          </Grid>
        </Grid>
        {isSecretary && (
          <DialogActions sx={{ mt: 1 }}>
            <Button onClick={onCancel}>Annulla</Button>
            <Button
              type="submit"
              autoFocus
              disabled={!formMethods.formState.isDirty}
            >
              Salva
            </Button>
          </DialogActions>
        )}
      </form>
    </FormProvider>
  );
};

export default EditAwardeePublicDataForm;

import { AwardeePrivateData } from '../../../../lib/types';
import GenericFormDialogButton from '../../../common/dialogs/GenericFormDialogButton';
import EditAwardeePrivateDataForm from '../forms/EditAwardeePrivateDataForm';

interface Props {
  awardeePrivateData: AwardeePrivateData;
}

const EditAwardeePrivateDataDialogButton: React.FC<Props> = ({
  awardeePrivateData,
}) => (
  <GenericFormDialogButton
    color="info"
    variant="text"
    buttonLabel="Modifica"
    dialogTitle="Modifica dati privati"
    renderForm={(handleClose) => (
      <EditAwardeePrivateDataForm
        awardeePrivateData={awardeePrivateData}
        handleClose={handleClose}
      />
    )}
  />
);

export default EditAwardeePrivateDataDialogButton;

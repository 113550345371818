import {
  CipherData,
  decrypt as _decrypt,
  encrypt as _encrypt,
  importKey as _importKey,
} from 'easy-web-crypto';

const buf2b64str = (buf: ArrayBuffer) =>
  btoa(String.fromCharCode(...new Uint8Array(buf)));

const b64str2buf = (b64str: string) =>
  Uint8Array.from(atob(b64str), (c) => c.charCodeAt(0));

const encrypt = async (
  key: CryptoKey,
  plainData: string | object
): Promise<CipherData> => _encrypt(key, plainData);

const decrypt = async <T>(key: CryptoKey, cipherData: CipherData): Promise<T> =>
  _decrypt(key, cipherData).then((plainData) => plainData as unknown as T);

const importKey = async (b64strKey: string): Promise<CryptoKey> =>
  _importKey(b64str2buf(b64strKey));

const exports = {
  encrypt,
  decrypt,
  importKey,
  buf2b64str,
};

export default exports;

import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';

import SplashWrapper from '../components/layout/SplashWrapper';
import { LOGO_SIZE } from '../config';

const LoadingPage = (): JSX.Element => {
  return (
    <SplashWrapper>
      <Box sx={{ width: LOGO_SIZE, height: '42.25px' }}>
        <LinearProgress />
      </Box>
    </SplashWrapper>
  );
};

export default LoadingPage;

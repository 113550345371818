import { Grid, Typography } from '@mui/material';

import { useAwardEditions } from '../../lib/context/useAwardEditions';
import { AwardEditionVotingState as VotingState } from '../../lib/types';
import AwardEditionManager from './AwardEditionManager';
import AwardEditionRanking from './AwardEditionRanking';

const AwardEditionManagerWrapper = (): JSX.Element => {
  const { selectedAwardEdition } = useAwardEditions();

  if (!selectedAwardEdition) {
    return (
      <Grid container height="100%" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography>Selezionare un premio</Typography>
        </Grid>
      </Grid>
    );
  }

  if (selectedAwardEdition.votingState !== VotingState.VOTING_ENDED) {
    return <AwardEditionManager awardEdition={selectedAwardEdition} />;
  }

  return <AwardEditionRanking awardEdition={selectedAwardEdition} />;
};

export default AwardEditionManagerWrapper;
